import { action, computed, flow, makeObservable, observable } from "mobx";
import { DateTime } from "luxon";

import { blkReader, UFINT_LATEST_FORECAST_BLOCK } from "@conf/blocks";
import * as CONSTANT from "./Forecast.constants";

class ForecastStore {
  resource_type = CONSTANT.SUBSTATION;

  resource_id = null;

  prevFilter = null;

  filter = CONSTANT.LATEST;

  training_start_year = null;

  training_start_month = null;

  training_end_year = null;

  training_end_month = null;

  training_period = null;

  simulation_start_year = null;

  simulation_start_month = null;

  simulation_end_year = null;

  simulation_end_month = null;

  simulation_period = null;

  fetching = false;

  data_available = false;

  error = false;

  error_msg = null;

  constructor(parent) {
    makeObservable(this, {
      training_period: true,
      simulation_period: true,
      paddedMonth: true,
      fetchLatestForecast: true,
      fetchAPIDetails: true,
      setPeriods: true,
      resource_type: observable,
      resource_id: observable,
      filter: observable,
      prevFilter: observable,
      training_start_year: observable,
      training_start_month: observable,
      training_end_year: observable,
      training_end_month: observable,
      simulation_start_year: observable,
      simulation_start_month: observable,
      simulation_end_year: observable,
      simulation_end_month: observable,
      fetching: observable,
      data_available: observable,
      error: observable,
      error_msg: observable,
      updateResourceType: action.bound,
      updateAttr: action.bound,
      updateResourceId: action.bound,
      updateFilter: action.bound,
      updatePrevFilter: action.bound,
      form_incomplete: computed,
      getyearOptions: action.bound,
      resetForNetwork: action.bound,
      formSimDate: action.bound,
    });

    this.parent = parent;
    this.RESOURCE_OPTIONS = new Map([
      [CONSTANT.CLUSTER, "text_cluster"],
      [CONSTANT.SUBSTATION, "text_substation"],
    ]);
    this.BLOCK = null;
    this.latest_forecast_approval_date = null;
    this.fetchAPIDetails = this.fetchAPIDetails.bind(this);
  }

  updateResourceType(value) {
    if (this.resource_type !== value) {
      this.resource_type = value;
      this.resource_id = null;
    }
  }

  updateAttr(attr, value) {
    this[attr] = value;
  }

  updateResourceId(value) {
    this.resource_id = value;
  }

  updateFilter(newFilter) {
    this.filter = newFilter;
    const { currentHour } = this.parent.networks;
    let fetchFutureDay = CONSTANT.FETCH_MAX_DAYS_IN_FUTURE;

    if ([CONSTANT.SIMULATION].includes(this.filter)) {
      fetchFutureDay = 0;
    }
    // update the DataRangePicker base on filter
    this.setPeriods(currentHour, currentHour.plus({ days: fetchFutureDay }));
  }

  updatePrevFilter(newFilter) {
    this.prevFilter = newFilter;
  }

  get form_incomplete() {
    return this.resource_id === null;
  }

  getyearOptions({ startedYear }) {
    //* get years from `startedYear` to the current year
    const { currentHour } = this.parent.networks;
    const diff = currentHour.diff(DateTime.fromISO(`${startedYear}-01-01`), ["years"]);
    const years = [];

    for (let i = 0; i <= diff.years; i++) {
      years.push(currentHour.plus({ years: -1 * i }).year);
    }
    return years;
  }

  // eslint-disable-next-line class-methods-use-this
  formSimDate({ year, month, boundary }) {
    if (boundary === "start") {
      return DateTime.fromObject({ year, month }).startOf("month");
    }
    return DateTime.fromObject({ year, month }).endOf("month");
  }

  // eslint-disable-next-line class-methods-use-this
  paddedMonth(month) {
    return String(month).padStart(2, "0");
  }

  resetForNetwork() {
    this.filter = CONSTANT.LATEST;
    this.prevFilter = null;
    this.resource_type = CONSTANT.CLUSTER;
    const {
      networks: { currentHour, allSubstationCluster },
    } = this.parent;
    const defaultResourceId = allSubstationCluster;
    if (defaultResourceId !== this.resource_id) {
      this.resource_id = defaultResourceId;
    }
    //* training period
    const trainingStartDate = currentHour.minus({ months: 18 });
    this.training_start_month = this.paddedMonth(trainingStartDate.month);
    this.training_start_year = trainingStartDate.year;
    this.training_end_month = this.paddedMonth(currentHour.month);
    this.training_end_year = currentHour.year;

    //* simulation period
    this.simulation_start_month = this.paddedMonth(currentHour.month);
    this.simulation_start_year = currentHour.year;

    this.simulation_end_month = this.paddedMonth(currentHour.month);
    this.simulation_end_year = currentHour.year;
  }

  // *******
  //* FETCH LATEST FORECAST
  // *******
  fetchLatestForecast = flow(function* fetchLatestForecast() {
    this.fetching = true;
    this.data_available = false;
    this.BLOCK = {};
    this.error = false;
    this.error_msg = null;
    this.prevFilter = null;

    const blockName = UFINT_LATEST_FORECAST_BLOCK.to_block_name();
    const latestForecastBlock = yield this.parent.newapi.getInfoBlocksV4({
      network_id: this.parent.networks.current_network.uid,
      resource_id: this.resource_id,
      resource_type: this.resource_type,
      block_names: [blockName],
    });

    if (latestForecastBlock) {
      const reader = blkReader(latestForecastBlock, [
        ["name", [blockName, "approved_time"], "approved_time"],
        ["name", [blockName, "training_date_min"], "training_date_min"],
        ["name", [blockName, "training_date_max"], "training_date_max"],
      ]);

      const latestForecast = reader(this.resource_id);
      this.latest_forecast_approval_date = latestForecast?.approved_time || null;
      if (this.latest_forecast_approval_date) {
        this.latest_forecast_approval_date = DateTime.fromISO(
          this.latest_forecast_approval_date
        ).toFormat("dd MMM, yyyy HH:mm");
      }

      const trainingStart = latestForecast?.training_date_min;
      if (trainingStart) {
        const [trainingStartYear, trainingStartMonth] = trainingStart;
        this.training_start_year = trainingStartYear;
        this.training_start_month = this.paddedMonth(trainingStartMonth);
      }

      const trainingEnd = latestForecast?.training_date_max;
      if (trainingEnd) {
        const [trainingEndYear, trainingEndMonth] = trainingEnd;
        this.training_end_year = trainingEndYear;
        this.training_end_month = this.paddedMonth(trainingEndMonth);
      }
    }

    try {
      const { networks, newapi } = this.parent;
      const latestForecastStart = networks.currentHour;
      const latestForecastEnd = networks.currentHour.plus({
        days: CONSTANT.FETCH_MAX_DAYS_IN_FUTURE,
      });
      const components = [
        "t",
        "heat_energy",
        "volume",
        "returntemp",
        "heat_energy_lower",
        "volume_lower",
        "returntemp_lower",
        "heat_energy_upper",
        "volume_upper",
        "returntemp_upper",
      ];
      const data = yield newapi.getMeterDataV4({
        resource_type: this.resource_type,
        resource_uid: this.resource_id,
        network_id: networks.current_network.uid,
        ts_start: latestForecastStart,
        ts_end: latestForecastEnd,
        stage: CONSTANT.FORECAST,
        components,
      });
      this.data_available = true;

      this.setPeriods(latestForecastStart, latestForecastEnd);

      if (Object.keys(data.columns).length === 1) {
        throw Error("message_no_stored_forecast_available");
      }

      this.BLOCK = { ep_simulation: data };
    } catch (err) {
      this.error = true;
      this.error_msg = err.message;
    } finally {
      this.fetching = false;
    }
  });

  // *******
  //* CUSTOM FORECAST && CUSTOM SIMULATION
  // *******
  fetchAPIDetails = flow(function* fetchAPIDetails() {
    this.fetching = true;
    this.BLOCK = {};
    this.data_available = false;
    this.error = false;
    this.error_msg = null;

    const { networks } = this.parent;

    const dateValidation = (year, month) => {
      const newMonth = !month ? month : Number.parseInt(month);
      return year === networks.currentHour.year && newMonth > networks.currentHour.month;
    };

    const isTrainMonthValid =
      dateValidation(this.training_start_year, this.training_start_month) ||
      dateValidation(this.training_end_year, this.training_end_month);

    const isSimulationMonthValid =
      dateValidation(this.simulation_start_year, this.simulation_start_month) ||
      dateValidation(this.simulation_end_year, this.simulation_end_month);

    if (
      this.filter === CONSTANT.FORECAST
        ? isTrainMonthValid
        : isTrainMonthValid || isSimulationMonthValid
    ) {
      this.parent.notifications.error("Date should not be over current day", {
        autoClose: true,
      });
      this.fetching = false;
      this.data_available = false;
      return;
    }

    try {
      const params = {
        resource_type: this.resource_type,
      };
      if (this.resource_type === CONSTANT.CLUSTER) {
        params.resource_id = networks.active_clusters.get(this.resource_id);
      } else {
        params.resource_id = networks.active_substations.get(this.resource_id);
      }
      params.meter_type = ["heat_energy", "returntemp", "volume"];
      params.return_predictors = ["t"];

      // training date
      params.mt_start_date = networks.dtFromYM(
        this.training_start_year,
        this.training_start_month,
        "start"
      );
      params.mt_end_date = networks.dtFromYM(
        this.training_end_year,
        this.training_end_month,
        "end"
      );

      // simulation date
      if ([CONSTANT.LATEST, CONSTANT.FORECAST].includes(this.filter)) {
        params.cs_start_date = networks.currentHour;
        params.cs_end_date = networks.currentHour.plus({
          days: CONSTANT.FETCH_MAX_DAYS_IN_FUTURE,
        });
      } else {
        params.cs_start_date = this.formSimDate({
          year: this.simulation_start_year,
          month: this.simulation_start_month,
          boundary: "start",
        });
        // this const was created, so we could get access to daysInMonth(line.329). We couldn't get it directly from params.cs_end_date
        const simEndDate = this.formSimDate({
          year: this.simulation_end_year,
          month: this.simulation_end_month,
          boundary: "end",
        });
        params.cs_end_date = simEndDate.set({ day: simEndDate.daysInMonth });
      }

      const [epData, meterData] = yield Promise.all([
        this.parent.newapi.getEPOData(params),
        this.parent.newapi.getMeterDataV4({
          resource_type: this.resource_type,
          resource_uid: this.resource_id,
          ts_start: params.cs_start_date,
          network_id: networks.current_network.uid,
          ts_end: params.cs_end_date,
          components: params.meter_type,
        }),
      ]);

      if (epData.ep_simulation.data.length === 0 && meterData.data.length === 0) {
        this.data_available = false;
        this.error = true;
        this.error_msg = "message_no_data_available";
        return;
      }

      if (epData) {
        this.BLOCK.ep_simulation = epData.ep_simulation;
      }
      if (meterData) {
        this.BLOCK.meter_data = meterData;
      }

      this.setPeriods(params.cs_start_date, params.cs_end_date);
      this.data_available = true;
    } catch (err) {
      this.error = true;
      this.error_msg = "message_unable_to_get_a_result";
    } finally {
      this.fetching = false;
    }
  });

  setPeriods = function setPeriods(simulationStart, simulationEnd) {
    this.training_period = {
      start: DateTime.fromObject({
        year: this.training_start_year,
        month: this.training_start_month,
      }),
      end: DateTime.fromObject({
        year: this.training_end_year,
        month: this.training_end_month,
      }),
    };

    this.simulation_start_year = simulationStart.c.year;
    this.simulation_start_month = this.paddedMonth(simulationStart.c.month);
    this.simulation_end_year = simulationEnd.c.year;
    this.simulation_end_month = this.paddedMonth(simulationEnd.c.month);

    this.simulation_period = {
      start: simulationStart,
      end: simulationEnd,
    };
  };
}

// eslint-disable-next-line import/no-default-export
export default ForecastStore;
