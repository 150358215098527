/* eslint-disable react/jsx-props-no-spreading */
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isObservable, toJS } from "mobx";
import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";
import { getFixedT, type TOptions } from "i18next";
import {
  MRT_ToggleDensePaddingButton as ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton as ToggleFullScreenButton,
  type MRT_ColumnOrderState,
  type MRT_GroupingState,
  type MRT_Row,
  type MRT_RowData,
  type MRT_SortingState,
  type MRT_TableState,
  type MRT_VisibilityState,
} from "material-react-table";

import { R12 } from "@config/constants";
import { APP_BAR_HEIGHT, BOTTOM_ACTION_BAR_HEIGHT } from "@config/ui_constants";
import { logger as baseLogger } from "@core/logger";
import { getCoreBlockName } from "@core/utils/columns/utils";

import ColumnManager from "../ColumnManager/ColumnManager";
import UtfTable from "../Table/UtfTable";
import type { UtfTableProps } from "../Table/utils";
import { MIN_TABLE_HEIGHT } from "../Table/utils";
import DefaultCell from "./Cell";
import { MIN_COLUMN_WIDTH, type InfoBlockColumn, type MonthOption, type YearOption } from "./const";
import ExportButton from "./ExportFile";
import DefaultHeader from "./Header";

const logger = baseLogger.getSubLogger({ name: "InfoBlockGrid" });

const aggregationColums = [
  "core.heat_energy_sum",
  "average_heat_energy",
  "core.volume_sum",
  "average_volume",
  "epcore_normalized.heat_energy_sum",
  "epcore_normalized.volume_sum",
  "ep_systemdesign_1h.volume",
  "ep_systemdesign_24h.volume",
  "ep_systemdesign_1h.heat_energy",
  "ep_systemdesign_24h.heat_energy",
];

/**
 * Replaces the core block name in the given column path.
 */
function replaceCoreId(columnPath: string, isRolling: boolean) {
  return columnPath.replace(`${getCoreBlockName(!isRolling)}.`, `${getCoreBlockName(isRolling)}.`);
}

/**
 * Returns the translated string if the field is not null.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function t(field: any, i18nOptions: TOptions): string {
  const t = getFixedT("en", i18nOptions.ns ?? "_common");
  return field ? t(field, i18nOptions) : field;
}

/**
 * Populates the column alignment related properties
 */
function getColAlignmentProps({ align, muiTableBodyCellProps = {}, type }: InfoBlockColumn) {
  const defaultAlign = type === "number" ? "right" : "left";
  const alignment = align ?? defaultAlign;

  return {
    align: alignment,
    muiTableBodyCellProps: { ...muiTableBodyCellProps, align: alignment },
  };
}

/**
 * Populates the column filter related properties
 */
function getColFilterProps({ type }: InfoBlockColumn) {
  const filterProps: Partial<InfoBlockColumn> = {};
  const typeToVariant: { [type: string]: InfoBlockColumn["filterVariant"] } = {
    string: "autocomplete",
    number: "text",
    link: "text",
  };
  const typeToFilterFn: { [type: string]: InfoBlockColumn["filterFn"] } = {
    number: "between",
  };
  const stringModes = ["fuzzy", "contains", "startsWith", "endsWith", "empty", "notEmpty"];
  const typeToColumnFilterModes: { [type: string]: InfoBlockColumn["columnFilterModeOptions"] } = {
    link: stringModes,
    string: stringModes,
    number: [
      "equals",
      "notEquals",
      "between",
      "betweenInclusive",
      "inNumberRange",
      "greaterThan",
      "greaterThanOrEqualTo",
      "lessThan",
      "lessThanOrEqualTo",
      "empty",
      "notEmpty",
    ],
  };

  // filterVariant
  if (type in typeToVariant) {
    filterProps.filterVariant = typeToVariant[type];
  } else {
    filterProps.enableColumnFilter = false;
  }

  // filterFn
  if (type in typeToFilterFn) filterProps.filterFn = typeToFilterFn[type];

  // columnFilterModeOptions
  if (type in typeToColumnFilterModes)
    filterProps.columnFilterModeOptions = typeToColumnFilterModes[type];

  return filterProps;
}

/**
 * Populates the "aggregation" related properties
 */
function getColAggregationProps({ type, id }: InfoBlockColumn) {
  const props: Partial<InfoBlockColumn> = {};
  const typeToAggregationFn: { [type: string]: InfoBlockColumn["aggregationFn"] } = {
    number: "sum",
  };
  const typeToAggregatedCell = {
    number: ({ cell }) => {
      return (
        // eslint-disable-next-line i18next/no-literal-string
        <Typography variant="body1" fontWeight="400">
          Total by {cell.column.columnDef.header}: <br />{" "}
          <DefaultCell cell={cell} column={cell.column} />
        </Typography>
      );
    },
  };
  const typeToFooter = {
    number: ({ table, column }) => (
      // eslint-disable-next-line i18next/no-literal-string
      <Typography variant="body1" fontWeight="500">
        Total {column.columnDef.header}: <br />
        <DefaultCell
          // @ts-expect-error getValue is not assingable
          cell={{
            getValue() {
              return table
                .getFilteredRowModel()
                .flatRows.reduce((acc: number, row: MRT_Row<MRT_RowData>) => {
                  if (row.getValue(column.id)) return acc + Number(row.getValue(column.id));
                  return acc;
                }, 0);
            },
          }}
          column={column}
        />
      </Typography>
    ),
  };

  // aggregationFn
  if (type in typeToAggregationFn) props.aggregationFn = typeToAggregationFn[type];

  // AggregatedCell
  if (type in typeToAggregatedCell && id && aggregationColums.includes(id))
    props.AggregatedCell = typeToAggregatedCell[type];

  // Footer
  if (type in typeToFooter && id && aggregationColums.includes(id))
    props.Footer = typeToFooter[type];

  return props;
}

/**
 * Maps the column with default values and translations.
 */
function mapColumnWithDefaults(
  _column: InfoBlockColumn,
  enableAggregation: boolean
): InfoBlockColumn {
  const column = toJS(_column);
  const { info, header, sublabel, translateNs, translateOptions = {}, id, accessorKey } = column;
  let i18nOptions = { ns: translateNs, ...translateOptions };

  let modifiedColumn = {
    ...column,
    header: t(header, i18nOptions),
    sublabel: sublabel ? t(sublabel, i18nOptions) : sublabel,
    info: info ? t(info, i18nOptions) : info,
    muiTableHeadCellProps: { "data-testid": `utf-col-${id ?? accessorKey}` },
    ...getColAlignmentProps(column),
    ...getColFilterProps(column),
  };

  if (enableAggregation) {
    modifiedColumn = {
      ...modifiedColumn,
      ...getColAggregationProps(column),
    };
  }

  modifiedColumn.columns = column.columns?.map((_childColumn) => {
    const childColumn = toJS(_childColumn);

    if (childColumn.translateNs) i18nOptions.ns = childColumn.translateNs;
    i18nOptions = { ...i18nOptions, ...(childColumn.translateOptions ?? {}) };

    let modifiedChildColumn = {
      ...childColumn,
      header: t(childColumn.header, i18nOptions),
      sublabel: childColumn.sublabel ? t(childColumn.sublabel, i18nOptions) : childColumn.sublabel,
      info: childColumn.info ? t(childColumn.info, i18nOptions) : childColumn.info,
      muiTableHeadCellProps: {
        "data-testid": `utf-col-${childColumn.id ?? childColumn.accessorKey}`,
      },
      ...getColAlignmentProps(childColumn),
      ...getColFilterProps(childColumn),
    };
    if (enableAggregation) {
      modifiedChildColumn = {
        ...modifiedChildColumn,
        ...getColAggregationProps(childColumn),
      };
    }

    return modifiedChildColumn;
  });

  return modifiedColumn;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getFilteredFlatColumns(userColumns: InfoBlockColumn[], filterFn: any) {
  return [
    ...userColumns.filter(filterFn).map((col) => col.id),
    ...userColumns
      .filter((col) => col.columns)
      .filter(filterFn)
      .flatMap((col) => col?.columns?.map((subCol) => subCol.id)),
  ];
}

export type InfoBlockGridProps = {
  state?: MRT_TableState<MRT_RowData>;
  columns: InfoBlockColumn[];
  data: MRT_RowData[];
  sortBy?: MRT_SortingState;
  columnVisibility?: MRT_VisibilityState;
  columnOrder?: MRT_ColumnOrderState;
  grouping?: MRT_GroupingState;
  showColumnManager?: boolean;
  onColumnManagerClose?: () => void;
  onColumnsUpdated?: (columns: {
    columnOrder?: MRT_ColumnOrderState;
    columnVisibility?: MRT_VisibilityState;
  }) => void;
  year?: YearOption;
  month?: MonthOption;
  stickyHeader?: boolean;
  footerActions?: React.ReactNode;
  loading?: boolean;
  exportFileName?: string;
  enableAggregation?: boolean;
  topOffset?: number;
} & UtfTableProps<MRT_RowData>;

const gaps =
  APP_BAR_HEIGHT +
  BOTTOM_ACTION_BAR_HEIGHT +
  // + margins
  60;

const InfoBlockGridPure: React.FC<InfoBlockGridProps> = ({
  columns: userColumns,
  data: userData,
  state = {},
  sortBy = [{ id: "id", desc: true }],
  grouping: userGrouping = [],
  columnVisibility: userColumnVisibility = {},
  columnOrder: userColumnOrder,
  showColumnManager = false,
  onColumnManagerClose,
  onColumnsUpdated,
  year,
  month,
  stickyHeader = false,
  footerActions = null,
  loading: isLoading = false,
  exportFileName,
  enableAggregation = false,
  topOffset = 0,
  ...utfTableProps
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showColMan, setShowColMan] = useState<boolean>(false);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({});
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);
  const [grouping, setGrouping] = useState<MRT_GroupingState>(userGrouping);
  const [sorting, setSorting] = useState<MRT_SortingState>(sortBy);
  const isRollingYear = year === R12;

  // Flatten observables in user provided props
  const data = useMemo(() => {
    let rawData = userData;
    // check first row before iterating through all rows for performance
    if (isObservable(rawData[0])) {
      rawData = rawData.map((row) => toJS(row));
    }
    return rawData;
  }, [userData]);
  const rowCount = data.length;
  // Modify user provided column definitions for;
  // - Translating related fields with user defined translation options
  // - Setting the column alignment based on its type ("numbers" always aligned to the right)
  // - Chosing which "filter variant" to be used for column types
  const columns = useMemo(
    () => userColumns.map((column) => mapColumnWithDefaults(column, enableAggregation)),
    [enableAggregation, userColumns]
  );

  // Get table state with adjusted column IDs based on isRollingYear.
  // This handles converting column IDs between "core" and "core_rolling" prefixes.
  const tableState = useMemo(() => {
    // Get the column ID updated for the current isRollingYear value.
    const getUpdatedId = (id: string) => replaceCoreId(id, isRollingYear);

    const modifiedState: Partial<MRT_TableState<MRT_RowData>> = {
      isLoading,
      ...state,

      columnVisibility: Object.entries(columnVisibility).reduce(
        (vis: Record<string, boolean>, [id, isVisible]: [string, boolean]) => {
          vis[getUpdatedId(id)] = isVisible;
          return vis;
        },
        {}
      ),

      sorting: sorting.map(({ id, desc }) => ({
        id: getUpdatedId(id),
        desc,
      })),

      grouping: grouping.map(getUpdatedId),
    };

    if (columnOrder.length > 0) modifiedState.columnOrder = columnOrder.map(getUpdatedId);

    return modifiedState;
  }, [columnOrder, columnVisibility, grouping, isLoading, isRollingYear, sorting, state]);

  // Update column statuses from the changes coming from the column manager
  const handleOnColumnsUpdated = useCallback(
    (updatedColumns: {
      columnOrder?: MRT_ColumnOrderState;
      columnVisibility?: MRT_VisibilityState;
    }) => {
      if (onColumnManagerClose) onColumnManagerClose();

      if (!updatedColumns) return;

      const { columnOrder: nextColumnOrder, columnVisibility: nextColumnVisibility } =
        updatedColumns;

      if (Array.isArray(nextColumnOrder)) {
        setColumnOrder(nextColumnOrder);
      }

      if (nextColumnVisibility) {
        setColumnVisibility(nextColumnVisibility);
      }

      if (onColumnsUpdated) onColumnsUpdated(updatedColumns);
    },
    [onColumnManagerClose, onColumnsUpdated, setColumnOrder]
  );

  // Hide invisible columns on mount
  useEffect(() => {
    const hiddenColumnIds = getFilteredFlatColumns(
      userColumns,
      ({ isVisible }: { isVisible: boolean }) => !isVisible
    );
    logger.debug(
      "INITIAL RENDER: Hiding %s columns with `isVisible=false` defined",
      hiddenColumnIds.length
    );

    setColumnVisibility((visibility: MRT_VisibilityState) => ({
      ...visibility,
      ...hiddenColumnIds.reduce((acc: MRT_VisibilityState, id?: string) => {
        if (id) acc[id] = false;
        return acc;
      }, {}),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hide disabled columns
  useEffect(() => {
    const disabledColumnIds = getFilteredFlatColumns(
      userColumns,
      ({ disabled }: { disabled: boolean }) => disabled
    );
    logger.debug("Hiding %s columns with `disabled=true` defined", disabledColumnIds.length);
    setColumnVisibility((visibility: MRT_VisibilityState) => ({
      ...visibility,
      ...disabledColumnIds.reduce((acc: MRT_VisibilityState, id?: string) => {
        if (id) acc[id] = false;
        return acc;
      }, {}),
    }));
  }, [setColumnVisibility, userColumns]);

  // Toggle column manager visibility
  useEffect(() => {
    setShowColMan(Boolean(showColumnManager));
  }, [showColumnManager]);

  const heightOffset = useMemo(() => {
    if (!data.length || !columns) return;
    const tableContainer = containerRef.current;
    const distanceToTop = !tableContainer ? gaps : tableContainer.getBoundingClientRect().top;
    return Math.max(distanceToTop + gaps + topOffset, MIN_TABLE_HEIGHT);
  }, [data, columns, topOffset]);

  logger.debug("[RENDER] %j", {
    isLoading,
    sortBy,
    rowCount,
    heightOffset,
  });

  return (
    <div ref={containerRef}>
      <UtfTable
        layoutMode="grid"
        defaultColumn={{
          // @ts-expect-error TO-BE-IDENTIFIED
          Cell: DefaultCell,
          // @ts-expect-error TO-BE-IDENTIFIED
          Header: DefaultHeader,
          size: MIN_COLUMN_WIDTH,
          minSize: MIN_COLUMN_WIDTH,
        }}
        // @ts-expect-error TO-BE-IDENTIFIED
        columns={columns}
        data={data}
        rowCount={rowCount}
        initialState={{
          sorting: sortBy,
          columnVisibility: userColumnVisibility,
          columnOrder: userColumnOrder,
          grouping: userGrouping,
        }}
        state={tableState}
        sortDescFirst
        enableColumnActions
        enableColumnResizing
        enableColumnFilters
        enableColumnFilterModes
        enableFacetedValues
        enableStickyHeader={stickyHeader}
        onSortingChange={setSorting}
        onColumnOrderChange={setColumnOrder}
        onColumnVisibilityChange={setColumnVisibility}
        onGroupingChange={setGrouping}
        positionToolbarAlertBanner="none"
        columnFilterDisplayMode="popover"
        // renderers
        // @ts-expect-error TO-BE-IDENTIFIED
        renderBottomToolbarCustomActions={footerActions}
        renderToolbarInternalActions={({ table }) => (
          <Box sx={{ alignItems: "center", display: "flex", zIndex: 3 }}>
            {/* Density Toggler */}
            <ToggleDensePaddingButton table={table} color="primary" />
            {/* Fullscreen Toggler */}
            <ToggleFullScreenButton table={table} />
            {/* CSV & XLSX exporter */}
            {/* @ts-expect-error TO-BE-REFACTORED */}
            <ExportButton table={table} filename={exportFileName} disabled={isLoading} />
          </Box>
        )}
        // styles
        muiTablePaperProps={{
          sx: {
            "&&": {
              borderColor: "white",
            },
          },
        }}
        muiTableContainerProps={{
          // @ts-expect-error TO-BE-IDENTIFIED
          "data-testid": "utf-infoblock-grid",
          sx: {
            minHeight: MIN_TABLE_HEIGHT,
            maxHeight: `calc(100vh - ${heightOffset}px)`,
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            pt: 2,
            // Highlight the header group/parent
            '&:not([colspan="1"])': {
              borderBottom: "1px solid #bdbdbd",
            },
          },
        }}
        muiTopToolbarProps={{
          sx: {
            backgroundColor: "#fff",
            boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
          },
        }}
        {...utfTableProps}
      />

      {/* Column Manager UI for Adding/Removing/Sorting datatable columns */}
      {showColMan && (
        <ColumnManager
          columnOrder={columnOrder}
          columnVisibility={columnVisibility}
          onColumnsUpdated={handleOnColumnsUpdated}
          // @ts-expect-error TO-BE-IDENTIFIED
          columns={columns}
          year={year}
          month={month}
        />
      )}
    </div>
  );
};

// <InfoBlockGrid />
export const InfoBlockGrid: FC<InfoBlockGridProps> = observer(InfoBlockGridPure);

InfoBlockGrid.displayName = "InfoBlockGrid";

// for testing purpose only
export default InfoBlockGridPure;
