const baseUrl = "https://wiki.energypredict.com";

const defaultLang = "en";

export type WikiDataItem = {
  base: string;
  tabs?: {
    [tabName: string]: string;
  };
};

type Keys = keyof typeof wikiData;

export type WikiData =
  | {
      // @ts-expect-error huh?
      [feature: Keys]: WikiDataItem;
    }
  | {
      extend_tab: {
        [feature: string]: string;
      };
    };

export const wikiData = {
  dash: {
    base: `${baseUrl}/${defaultLang}/home`,
  },
  metering: {
    base: `${baseUrl}/${defaultLang}/metering/metering_dashboard`,
  },
  sales: {
    base: `${baseUrl}/${defaultLang}/sales/sales_dashboard`,
  },
  pricing: {
    base: `${baseUrl}/${defaultLang}/sales/pricing`,
  },
  distribution: {
    base: `${baseUrl}/${defaultLang}/distribution/distribution_dashboard`,
  },
  production: {
    base: `${baseUrl}/${defaultLang}/production/production_dashboard`,
  },
  forecast: {
    base: `${baseUrl}/${defaultLang}/production/forecast`,
  },
  data_library: {
    base: `${baseUrl}/${defaultLang}/general/data_library`,
  },
  mapview: {
    base: `${baseUrl}/${defaultLang}/general/map_view`,
  },
  rt_analysis: {
    base: `${baseUrl}/${defaultLang}/distribution/return_temperature_analysis`,
    // Tabs are appended to the base url for the section
    tabs: {
      analysis: "analysis",
      table: "table",
      production_savings: "production_savings",
    },
  },
  design_load: {
    base: `${baseUrl}/${defaultLang}/distribution/design_load`,
    tabs: {
      trend: "design_load_trend",
      analysis: "design_load_analysis",
    },
  },
  fault_detection: {
    base: `${baseUrl}/${defaultLang}/metering/fault_detection/fault_detection_main`,
  },
  extend_tab: {
    substation_consumption: `${baseUrl}/global_features/substation_explore/substation_overview`,
    fault_detection: `${baseUrl}/metering/fault_detection/fault_detection_tab`,
  },
  optimization: {
    base: `${baseUrl}/${defaultLang}/production/optimization`,
    tabs: {
      operational: "",
      simulation: "simulation",
      config: "configuration_builder",
    },
  },
  scenario_analysis: {
    base: `${baseUrl}/${defaultLang}/production/scenario-analysis`,
    tabs: {
      saved_sims: "",
      simulation: `scenario-simulation`,
      builder: `scenario-builder`,
    },
  },
} as const;
