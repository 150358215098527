import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box, LinearProgress } from "@mui/material";

import { useInject } from "@hooks";
import { HeadingBar } from "@shared/ui/layout";

import { CLUSTER, SUBSTATION } from "../../SubList/constant";
import VerticalSeparator from "../../ui/VerticalSeparator";
import { Card, ColCompareCard } from "./UI";
import useOverview from "./useOverview";

const GRAPH_LEGEND = [
  { name: "text_normal_year", color: "graph.blue" },
  { name: "text_measured", color: "secondary.main" },
];

function Overview() {
  const { t } = useTranslation();
  const { networks, ui } = useInject("networks", "ui");
  const { consumption, blockData, temp, fetching } = useOverview({
    type: ui.is_subsummary_open ? SUBSTATION : CLUSTER,
  });

  return (
    <>
      {fetching && <LinearProgress />}

      <Box my={4} mx={3} display="flex" flexDirection="column" width="98%">
        <HeadingBar title={t("text_energy")} />

        <Box mt={1}>
          <Box display="flex" flexDirection="row" mt={1}>
            {/* Total Consumption */}
            <Box width={ui.main_area * 0.3} flexGrow={1}>
              <Card
                title={t("text_normal_year_consumption")}
                data={{ value: consumption.consumptionValue, trend: consumption.consumptionTrend }}
                dataStatus={{
                  isData: blockData.isDataExist(consumption.energyConsumption),
                  isError: blockData.infoBlockError,
                }}
                trendSuffix="%"
                dataTooltip={t("text_trend_compared_to_previous_year")}
                loading={fetching}
                subtext={networks.LastProcessedYear.year}
              />
            </Box>
            <VerticalSeparator />

            {/* Monthly Consumption */}
            <Box bgcolor="white" width={ui.main_area * 0.4} flexGrow={1}>
              <ColCompareCard
                title={`12 ${t("text_months")}`}
                dataStatus={{
                  isData: blockData.isDataExist(consumption.energyConsumption),
                  isError: blockData.infoBlockError,
                }}
                legend={GRAPH_LEGEND}
                categories={consumption.monthlyConsumption?.months}
                units={{ mainUnit: "kWh", comparisonUnit: "kWh" }}
                series1={{
                  data: consumption.monthlyConsumption?.first,
                  name: "normal",
                  label: t("text_normalized_energy"),
                }}
                series2={{
                  data: consumption.monthlyConsumption?.second,
                  name: "measured",
                  label: t("text_measured_energy"),
                }}
              />
            </Box>
            <VerticalSeparator />

            {/* Yearly Consumption */}
            <Box bgcolor="white" width={ui.main_area * 0.4} flexGrow={1}>
              <ColCompareCard
                title={`5 ${t("text_years")}`}
                dataStatus={{
                  isData: blockData.isDataExist(consumption.energyConsumption),
                  isError: blockData.infoBlockError,
                }}
                legend={GRAPH_LEGEND}
                categories={consumption.yearlyConsumption?.years}
                units={{ mainUnit: "kWh", comparisonUnit: "kWh" }}
                series1={{
                  data: consumption.yearlyConsumption?.first,
                  name: "normal",
                  label: t("text_normalized_energy"),
                }}
                series2={{
                  data: consumption.yearlyConsumption?.second,
                  name: "measured",
                  label: t("text_measured_energy"),
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box mt={4}>
          <HeadingBar
            title={t("text_average_return_temperatures")}
            addons={[{ text: t("text_flow-weighted") }]}
          />
        </Box>

        <Box mt={1}>
          <Box display="flex" flexDirection="row" mt={1}>
            {/* Average return temp */}
            <Box width={ui.main_area * 0.3} flexGrow={1}>
              <Card
                title={t("text_normal_year_return_temperature")}
                data={{ value: temp.returnTempValue, trend: temp.returnTempTrend }}
                dataStatus={{
                  isData: blockData.isDataExist(temp.averageReturnTemp),
                  isError: blockData.infoBlockError,
                }}
                trendSuffix="°C"
                temperatureUnit="°C"
                dataTooltip={t("text_trend_compared_to_previous_year")}
                loading={fetching}
                subtext={networks.LastProcessedYear.year}
              />
            </Box>
            <VerticalSeparator />

            {/* Monthly return temp */}
            <Box bgcolor="white" width={ui.main_area * 0.4} flexGrow={1}>
              <ColCompareCard
                title={`12 ${t("text_months")}`}
                dataStatus={{
                  isData: blockData.isDataExist(consumption.energyConsumption),
                  isError: blockData.infoBlockError,
                }}
                legend={GRAPH_LEGEND}
                categories={temp.monthlyReturnTemp?.months}
                units={{ mainUnit: "°C", comparisonUnit: "°C" }}
                series1={{
                  data: temp.monthlyReturnTemp?.first,
                  name: "normal",
                  label: t("text_normalized_return_temp"),
                }}
                series2={{
                  data: temp.monthlyReturnTemp?.second,
                  name: "measured",
                  label: t("text_measured_return_temp"),
                }}
              />
            </Box>
            <VerticalSeparator />

            {/* Yearly return temp */}
            <Box bgcolor="white" width={ui.main_area * 0.4} flexGrow={1}>
              <ColCompareCard
                title={`5 ${t("text_years")}`}
                dataStatus={{
                  isData: blockData.isDataExist(consumption.energyConsumption),
                  isError: blockData.infoBlockError,
                }}
                legend={GRAPH_LEGEND}
                categories={temp.yearlyReturnTemp?.years}
                units={{ mainUnit: "°C", comparisonUnit: "°C" }}
                series1={{
                  data: temp.yearlyReturnTemp?.first,
                  name: "normal",
                  label: t("text_normalized_return_temp"),
                }}
                series2={{
                  data: temp.yearlyReturnTemp?.second,
                  name: "measured",
                  label: t("text_measured_return_temp"),
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default observer(Overview);
