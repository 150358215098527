import { flow } from "mobx";
import { DateTime } from "luxon";

import { EPO_CHECK_DURATION } from "@conf/config";
import { CODES } from "@conf/errors";
import URLS from "@conf/urls";
import { formatDateAPI, sleep } from "@core/utils";

const URLMAP = {
  user_list: () => `/users`,
  user_detail: ({ resource_id }) => `/users/${resource_id}`,
  groups_list: () => "/groups",
  group_detail: ({ resource_id }) => `/groups/${resource_id}`,
  policy_list: () => "/policies",
  policy_detail: ({ resource_id }) => `/policies/${resource_id}`,
  policy_resources: ({ policy_uid, resource_type, resource_uid }) =>
    `/policies/${policy_uid}/${resource_type}s/${resource_uid}`,
  group_policies: ({ group_uid }) => `/groups/${group_uid}/policies`,
  group_policy_detail: ({ group_uid, policy_uid }) => `/groups/${group_uid}/policies/${policy_uid}`,
  group_user_detail: ({ group_uid, user_uid }) => `/groups/${group_uid}/users/${user_uid}/`,
  network_data: ({}) => `/networks/info-data/`,
};

function ApiDetailError(obj) {
  this.error = obj.error;
  this.details = obj.details;
}

function ApiError(obj) {
  if (obj && obj.hasOwnProperty("error")) {
    this.error = obj.error;
  } else if (obj && obj.hasOwnProperty("message")) {
    this.error = obj.message;
  } else {
    this.error = obj;
  }
}

function urlWithParams(url, params = {}) {
  const searchParams = new URLSearchParams(Object.entries(params)).toString();
  return new URL(`${url}?${searchParams}`);
}

function StrRepr(msg) {
  if (typeof msg === "object") {
    return JSON.stringify(msg);
  }
  return msg;
}

const fetchTimeout = (url, ms, { signal, ...options } = {}) => {
  const controller = new AbortController();
  const promise = fetch(url, { signal: controller.signal, ...options });
  if (signal) signal.addEventListener("abort", () => controller.abort());
  const timeout = setTimeout(() => controller.abort(), ms);
  return promise.finally(() => clearTimeout(timeout));
};

export default class UtfApi {
  getNetworksForUserV4 = flow(function* getNetworksForUserV4() {
    const headers = yield this.parent.session.authHeaders();
    if (!headers) {
      return [[], 401];
    }
    try {
      const res = yield fetch(urlWithParams(`${URLS.mdslv4}/networks`, {}), { headers });
      if (res.ok) {
        const resdata = yield res.json();
        if (resdata.hasOwnProperty("data") && resdata.data.hasOwnProperty("collection")) {
          return [resdata.data.collection, 200];
        }
        return [[], 404];
      }
      return [[], res.status];
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        return [[], 404];
      }
    }
  });

  getNetworkResourcesV4 = flow(function* getNetworkResourcesV4(networkUid) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(urlWithParams(`${URLS.mdslv4}/networks/${networkUid}`, {}), {
        headers,
      });
      if (res.ok) {
        const resdata = yield res.json();
        return resdata.data;
      }
      console.log("unable to get network resources", res.status);
      return null;
    } catch (err) {
      this.processErrors(err, "getting resources for network");
      return null;
    }
  });

  registerUserInMDSL = flow(function* registerUserInMDSL() {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(`${URLS.mdslv4}/users`, {
          max_management_level: 4,
        }),
        { headers, method: "POST" }
      );
      return !!res.ok;
    } catch (err) {
      console.log(err);
      throw err;
    }
  });

  getNetworkInfoBlockV4 = flow(function* getNetworkInfoBlockV4({
    resource_ids,
    block_names,
    page_size = 0,
  }) {
    const headers = yield this.parent.session.authHeaders();
    let currentPage = 1;
    let haveMore = true;
    const params = {
      "uid[in]": resource_ids.join(","),
      "block_name[in]": block_names,
      page_size,
    };

    try {
      const collection = [];
      while (haveMore) {
        params.page = currentPage;
        const url = urlWithParams(`${URLS.mdslv4}/networks/info-data`, params);
        const res = yield fetch(url, { headers });
        if (res.ok) {
          const resJson = yield res.json();
          if (resJson.data && resJson.error === null) {
            for (const blk of resJson.data.collection) {
              collection.push(blk);
            }
            if (resJson.data.has_next_page) {
              currentPage += 1;
            } else {
              haveMore = false;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      return { collection };
    } catch (err) {
      return null;
    }
  });

  getNetworkSubstationInfoBlockV4 = flow(function* getNetworkSubstationInfoBlockV4({
    network_uid,
    block_names,
    resource_uids = [],
    page_size = 0,
  }) {
    const headers = yield this.parent.session.authHeaders();
    const params = {
      "block_name[in]": block_names,
      page_size,
    };
    let currentPage = 1;
    let haveMore = true;
    if (resource_uids.length > 0) {
      params["uid[in]"] = resource_uids.join(",");
    }
    try {
      const collection = [];
      while (haveMore) {
        params.page = currentPage;
        const url = urlWithParams(
          `${URLS.mdslv4}/networks/${network_uid}/substations/info-data`,
          params
        );
        const res = yield fetch(url, { headers });
        if (res.ok) {
          const resJson = yield res.json();
          if (resJson.data && resJson.error === null) {
            for (const blk of resJson.data.collection) {
              collection.push(blk);
            }
            if (resJson.data.has_next_page) {
              currentPage += 1;
            } else {
              haveMore = false;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      return { collection };
    } catch (err) {
      console.log("getInfoBlock", err);
      return null;
    }
  });

  getClusterSubstationInfoBlockV4 = flow(function* getClusterSubstationInfoBlockV4({
    cluster_uid,
    block_names,
    resource_uids = [],
    page_size = 0,
  }) {
    const headers = yield this.parent.session.authHeaders();
    let currentPage = 1;
    let haveMore = true;
    const params = {
      "block_name[in]": block_names,
      page_size,
    };
    if (resource_uids.length > 0) {
      params["uid[in]"] = resource_uids.join(",");
    }

    try {
      const collection = [];
      while (haveMore) {
        params.page = currentPage;
        const url = urlWithParams(
          `${URLS.mdslv4}/clusters/${cluster_uid}/substations/info-data`,
          params
        );
        const res = yield fetch(url, { headers });
        if (res.ok) {
          const resJson = yield res.json();
          if (resJson.data && resJson.error === null) {
            for (const blk of resJson.data.collection) {
              collection.push(blk);
            }
            if (resJson.data.has_next_page) {
              currentPage += 1;
            } else {
              haveMore = false;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      return { collection };
    } catch (err) {
      console.log("getInfoBlock", err);
      return null;
    }
  });

  getNetworkClusterInfoBlockV4 = flow(function* getNetworkClusterInfoBlockV4({
    network_uid,
    block_names,
    resource_uids = [],
    page_size = 0,
  }) {
    const headers = yield this.parent.session.authHeaders();
    let currentPage = 1;
    let haveMore = true;
    const params = {
      "block_name[in]": block_names,
      page_size,
    };
    if (resource_uids.length > 0) {
      params["uid[in]"] = resource_uids.join(",");
    }

    try {
      const collection = [];
      while (haveMore) {
        params.page = currentPage;
        const url = urlWithParams(
          `${URLS.mdslv4}/networks/${network_uid}/clusters/info-data`,
          params
        );
        const res = yield fetch(url, { headers });
        if (res.ok) {
          const resJson = yield res.json();
          if (resJson.data && resJson.error === null) {
            for (const blk of resJson.data.collection) {
              collection.push(blk);
            }
            if (resJson.data.has_next_page) {
              currentPage += 1;
            } else {
              haveMore = false;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      return { collection };
    } catch (err) {
      console.log("getInfoBlock", err);
      return null;
    }
  });

  getClusterMeterDataV4 = flow(function* getClusterMeterDataV4({
    network_uid,
    resource_uids = [],
    stage = "",
    meter = "",
    components = [],
    flags = [],
    range = {},
    ts_end = null,
    ts_start = null,
    page_size = 0,
  }) {
    const headers = yield this.parent.session.authHeaders();
    let currentPage = 1;
    let haveMore = true;
    const params = {
      "uid[in]": resource_uids.join(","),
      "component[in]": components.join(","),
      "stage[in]": stage,
      "meter[in]": meter,
      page_size,
      "datetime[ge]": ts_start.toISO(),
      "datetime[lt]": ts_end.toISO(),
    };
    if (flags.length > 0) {
      params["flag[in]"] = flags.join(",");
    }
    for (const key of Object.keys(range)) {
      params[key] = range[key];
    }
    if (resource_uids.length > 0) {
      params["uid[in]"] = resource_uids.join(",");
    }
    try {
      const readings = new Map();
      while (haveMore) {
        params.page = currentPage;
        const url = urlWithParams(
          `${URLS.mdslv4}/networks/${network_uid}/clusters/meter-data`,
          params
        );
        const res = yield fetch(url, { headers });
        if (res.ok) {
          const resJson = yield res.json();
          if (resJson.data && resJson.error === null) {
            for (const blk of resJson.data.collection) {
              const blk_id = `${blk.uid}-${blk.component}-${blk.stage}`;
              if (readings.has(blk_id)) {
                const prevReading = readings.get(blk_id);
                for (const iblk of blk.data) {
                  prevReading.data.push(iblk);
                }
              } else {
                readings.set(blk_id, blk);
              }
            }
            if (resJson.data.has_next_page) {
              currentPage += 1;
            } else {
              haveMore = false;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      return { collection: Array.from(readings.values()) };
    } catch (err) {
      console.log("get cluster meter data", err);
      return null;
    }
  });

  getClusterSubstations = flow(function* getClusterSubstations({ clusterUid }) {
    const headers = yield this.parent.session.authHeaders();
    const url = `${URLS.mdslv4}/clusters/${clusterUid}`;
    try {
      const res = yield fetch(url, { headers });
      if (res.ok) {
        const resJson = yield res.json();
        if (resJson.data && resJson.error === null) {
          return resJson.data;
        }
        return null;
      }
      return null;
    } catch (err) {
      console.log("getClusterSubstations", err);
      return null;
    }
  });

  getSubstationMeterDataV4 = flow(function* getSubstationMeterDataV4({
    network_uid,
    resource_uids = [],
    stage = "",
    meter = "",
    components = [],
    flags = [],
    range = {},
    ts_end = null,
    ts_start = null,
    page_size = 0,
  }) {
    const headers = yield this.parent.session.authHeaders();
    let currentPage = 1;
    let haveMore = true;
    const params = {
      "uid[in]": resource_uids.join(","),
      "component[in]": components.join(","),
      "stage[in]": stage,
      "meter[in]": meter,
      page_size,
      "datetime[ge]": ts_start.toISO(),
      "datetime[lt]": ts_end.toISO(),
    };
    for (const key of Object.keys(range)) {
      params[key] = range[key];
    }
    if (resource_uids.length > 0) {
      params["uid[in]"] = resource_uids.join(",");
    }
    if (flags.length > 0) {
      params["flag[in]"] = flags.join(",");
    }
    try {
      const readings = new Map();
      while (haveMore) {
        params.page = currentPage;
        const url = urlWithParams(
          `${URLS.mdslv4}/networks/${network_uid}/substations/meter-data`,
          params
        );
        const res = yield fetch(url, { headers });
        if (res.ok) {
          const resJson = yield res.json();
          if (resJson.data && resJson.error === null) {
            for (const blk of resJson.data.collection) {
              const blk_id = `${blk.uid}-${blk.component}-${blk.stage}`;
              if (readings.has(blk_id)) {
                const prevReading = readings.get(blk_id);
                for (const iblk of blk.data) {
                  prevReading.data.push(iblk);
                }
              } else {
                readings.set(blk_id, blk);
              }
            }
            if (resJson.data.has_next_page) {
              currentPage += 1;
            } else {
              haveMore = false;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      return { collection: Array.from(readings.values()) };
    } catch (err) {
      console.log("get cluster meter data", err);
      return null;
    }
  });

  getWeatherData = flow(function* getWeatherData({
    coordinate,
    start_date,
    end_date,
    metrics = "t",
  }) {
    try {
      const response = yield fetch(
        urlWithParams(`${URLS.weather}/weather-data/lon/${coordinate.lon}/lat/${coordinate.lat}`, {
          date_min: formatDateAPI(start_date),
          date_max: formatDateAPI(end_date),
          metrics,
          page_size: 0,
        }),
        { headers: { authorization: "WDSL wdsl=NEGEM6HKDM8PQENLOE9OUK3B614A7TZX" } }
      );
      const responseJson = yield response.json();
      if (Array.isArray(responseJson) && responseJson.length > 0) {
        const columns = ["datetime"];
        const data = [];

        // Convert each metric data into flattened array
        responseJson.forEach((collection, i) => {
          const colIndex = i + 1; // first index is always "datetime"
          const metricData = collection.data;
          columns.push(collection.metric);

          metricData.forEach((dataRow, dataIndex) => {
            if (!data[dataIndex]) data[dataIndex] = [];
            data[dataIndex][0] = dataRow.datetime;
            data[dataIndex][colIndex] = dataRow.value;
          });
        });

        return { columns, data };
      }
      throw Error("unable to get weather data");
    } catch (err) {
      console.log("getting weather data", err);
      throw Error("unable to get weather data");
    }
  });

  getEPDataAsync = flow(function* getEPDataAsync({
    resource_type,
    resource_id,
    mt_start_date,
    mt_end_date,
    cs_start_date,
    cs_end_date,
    network_name,
    return_predictors,
    meter_type = ["heatenergy", "supplytemp", "returntemp", "volume"],
    store_models,
  }) {
    const paramdata = {
      name: "forecast",
      params: {
        resource: { name: resource_id, type: resource_type, network: network_name },
        components: meter_type,
        training_range: { min: formatDateAPI(mt_start_date), max: formatDateAPI(mt_end_date) },
        model_type: "changepoint_v1",
        simulation_range: { min: formatDateAPI(cs_start_date), max: formatDateAPI(cs_end_date) },
        store_models,
      },
    };
    if (return_predictors) {
      paramdata.params.return_predictors = return_predictors;
    }
    const headers = yield this.parent.session.authHeaders();
    let requestId = null;
    let submitJobDetails = null;
    let jobStatusOk = false;
    let jobPostedOk = false;
    let res = null;
    try {
      res = yield fetch(urlWithParams(`${URLS.ep}/tasks/simulation`, {}), {
        method: "POST",
        headers,
        body: JSON.stringify(paramdata),
      });
      if (res.ok && (res.status === 202 || res.status === 200)) {
        submitJobDetails = yield res.json();
        if (submitJobDetails.hasOwnProperty("job_id")) {
          jobPostedOk = true;
        }
      }
    } catch (err) {
      console.log("postAPIRequest4Orchestration", err);
      throw Error("unable to post request");
    }
    if (!jobPostedOk) {
      throw Error("unable to get epo job result");
    }
    requestId = submitJobDetails.job_id;
    while (true) {
      yield sleep(EPO_CHECK_DURATION);
      try {
        res = yield fetch(urlWithParams(`${URLS.ep}/tasks/simulation/${requestId}`, {}), {
          headers,
        });
        const resJson = yield res.json();
        if (res.ok) {
          if (["error", "cancel"].indexOf(resJson.status) >= 0) {
            break;
          }
          if (resJson.status === "done") {
            jobStatusOk = true;
            break;
          }
        }
      } catch (err) {
        console.log("unable to get job status", err);
        throw Error("unable to get epo job result");
      }
    }
    if (!jobStatusOk) {
      throw Error("unable to get epo job result");
    }
    try {
      res = yield fetch(urlWithParams(`${URLS.ep}/tasks/simulation/${requestId}/result`, {}), {
        headers,
      });

      if (res.ok) {
        const rjson = yield res.json();
        return rjson.simulation;
      }
    } catch (err) {
      throw Error("unable to get epo job result");
    }
  });

  getFrontendFeatureList = flow(function* getFrontendFeatureList() {
    const headers = yield this.parent.session.authHeaders();
    const url = `${URLS.mdslv4}/config/frontend-features`;
    try {
      const res = yield fetch(url, { headers });
      if (res.ok) {
        const resJson = yield res.json();
        if (resJson.data && resJson.error === null) {
          return resJson.data.collection;
        }
      }
      return null;
    } catch (err) {
      console.log(`Failed to fetch "getFrontendFeatureList"`, err);
      return null;
    }
  });

  constructor(parent) {
    this.parent = parent;
  }

  processErrors(err, msg) {
    if (err.response) {
      switch (err.response.status) {
        case 400:
          this.parent.app.gotError(CODES.bad_request, {
            msg: StrRepr(msg),
            err: StrRepr(err.response.data.error),
          });
          break;
        case 401:
          this.parent.session.logout();
          break;
        case 500:
          this.parent.app.gotError(CODES.server_error, {
            msg: StrRepr(msg),
            err: StrRepr(err.response.data.error),
          });
          break;
        default:
          break;
      }
    } else {
      this.parent.app.gotError(CODES.unreachable, { msg, err: err.message });
    }
  }

  getCmsBlogs = flow(function* getCmsBlogs() {
    try {
      const controller = new AbortController();
      const res = yield fetchTimeout(urlWithParams(`${URLS.cms}/blogs/`, {}), 5000, {
        signal: controller.signal,
      });
      if (res.ok) {
        return yield res.json();
      }
      console.log("get cms blog", res.status);
    } catch (err) {
      console.log(err);
    }
  });

  getPreferences = flow(function* getPreferences() {
    const headers = yield this.parent.session.authHeaders();
    try {
      const controller = new AbortController();
      const res = yield fetchTimeout(urlWithParams(`${URLS.cms}/preferences/`, {}), 5000, {
        headers,
        signal: controller.signal,
      });
      if (res.ok) {
        return yield res.json();
      }
      console.log("get user preferences", res.status);
    } catch (err) {
      console.log(err);
    }
  });

  setPreferences = flow(function* setPreferences(pref) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const controller = new AbortController();
      const res = yield fetchTimeout(urlWithParams(`${URLS.cms}/preferences/`, {}), 5000, {
        headers,
        method: "POST",
        body: JSON.stringify(pref),
        signal: controller.signal,
      });
      if (res.ok) {
        return true;
      }
      console.log("get user preferences", res.status);
    } catch (err) {
      console.log(err);
    }
  });

  getSubstationNote = flow(function* getSubstationNote(substationID) {
    const headers = yield this.parent.session.authHeaders();
    let resp;

    try {
      resp = yield fetch(urlWithParams(`${URLS.mdslv4}/substations/${substationID}/notes`, {}), {
        headers,
      });
    } catch (err) {
      throw new ApiError(err);
    }

    const payload = yield resp.json();

    if (resp.ok) {
      return payload;
    }

    throw new ApiError(payload);
  });

  addSubstationNote = flow(function* addSubstationNote(substationID, note) {
    const headers = yield this.parent.session.authHeaders();
    let resp;
    const url = `${URLS.mdslv4}/substations/${substationID}/notes`;

    try {
      resp = yield fetch(urlWithParams(url, {}), {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        headers: {
          ...headers,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          "content-type": "application/json",
          accept: "application/json",
          path: substationID,
        },
        method: "POST",
        body: JSON.stringify(note),
      });
    } catch (err) {
      // eslint-disable-next-line ts/no-throw-literal
      throw new ApiError(err);
    }

    const payload = yield resp.json();

    if (resp.ok && resp.status === 201) {
      return true;
    }
    // eslint-disable-next-line ts/no-throw-literal
    throw new ApiError(payload);
  });

  /** ***************************************** pricing api ******************************************* */
  getConfigurations = flow(function* getConfigurations(params) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(urlWithParams(`${URLS.pricing}/pricing/configs`, params), {
        headers,
      });
      const resdata = yield res.json();
      if (res.ok) {
        return resdata.map((c) => ({
          name: c.name,
          category: c.category,
          id: c.id,
          created: DateTime.fromISO(c.created),
          updated: DateTime.fromISO(c.updated),
        }));
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      console.log("unable to get user configurations", err);
      throw new ApiError(err);
    }
  });

  updateConfiguration = flow(function* updateConfiguration(configId, data) {
    const headers = yield this.parent.session.authHeaders();
    let res;
    try {
      res = yield fetch(urlWithParams(`${URLS.pricing}/pricing/configs/${configId}`, {}), {
        headers,
        method: "PATCH",
        body: JSON.stringify(data),
      });
    } catch (err) {
      console.log(err);
      throw new ApiError(err);
    }
    const c = yield res.json();
    if (res.ok) {
      return {
        name: c.name,
        category: c.category,
        id: c.id,
        created: DateTime.fromISO(c.created, { zone: "UTC+00:00" }),
        updated: DateTime.fromISO(c.updated, { zone: "UTC+00:00" }),
      };
    }
    throw new ApiDetailError(c);
  });

  copyConfiguration = flow(function* copyConfiguration(configId) {
    const headers = yield this.parent.session.authHeaders();
    let res;
    try {
      res = yield fetch(
        urlWithParams(`${URLS.pricing}/pricing/configs/${configId}/duplicate`, {}),
        { headers, method: "POST" }
      );
    } catch (err) {
      console.log(err);
      throw new ApiError(err);
    }
    const c = yield res.json();
    if (res.ok) {
      return {
        name: c.name,
        category: c.category,
        id: c.id,
        created: DateTime.fromISO(c.created, { zone: "UTC+00:00" }),
        updated: DateTime.fromISO(c.updated, { zone: "UTC+00:00" }),
      };
    }
    throw new ApiDetailError(c);
  });

  createNewConfig = flow(function* createNewConfig({ network, name }) {
    const headers = yield this.parent.session.authHeaders();
    let res;
    try {
      res = yield fetch(urlWithParams(`${URLS.pricing}/pricing/configs`, {}), {
        headers,
        method: "POST",
        body: JSON.stringify({ network, name }),
      });
    } catch (err) {
      console.log(err);
      throw new ApiError(err);
    }
    const c = yield res.json();
    if (res.ok) {
      return {
        name: c.name,
        category: c.category,
        id: c.id,
        created: DateTime.fromISO(c.created, { zone: "UTC+00:00" }),
        updated: DateTime.fromISO(c.updated, { zone: "UTC+00:00" }),
      };
    }
    throw new ApiDetailError(c);
  });

  deleteConfig = flow(function* deleteConfig(configId) {
    const headers = yield this.parent.session.authHeaders();
    let res;
    try {
      res = yield fetch(urlWithParams(`${URLS.pricing}/pricing/configs/${configId}`, {}), {
        headers,
        method: "DELETE",
      });
    } catch (err) {
      console.log("unable to fetch", err.message);
      throw new ApiError(err);
    }
    const c = yield res.json();
    if (res.ok) {
      return {};
    }
    throw new ApiDetailError(c);
  });

  createPricingModelsForConfiguration = flow(function* createPricingModelsForConfiguration(
    configurationId,
    pm
  ) {
    const headers = yield this.parent.session.authHeaders();
    let res = null;
    try {
      res = yield fetch(
        urlWithParams(`${URLS.pricing}/pricing/configs/${configurationId}/price_models`, {}),
        {
          headers,
          method: "POST",
          body: JSON.stringify(pm),
        }
      );
    } catch (err) {
      console.log(err);
      throw new ApiError(err);
    }
    const resdata = yield res.json();
    if (res.ok) {
      return resdata;
    }
    throw new ApiDetailError(resdata);
  });

  getPriceModelsForConfiguration = flow(function* getPriceModelsForConfiguration(configurationId) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(`${URLS.pricing}/pricing/configs/${configurationId}/price_models`, {
          expand: "customer_groups,components",
        }),
        { headers }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      console.log(err);
      throw new ApiError(err);
    }
  });

  updatePricingModelsForConfiguration = flow(function* updatePricingModelsForConfiguration(
    configurationId,
    pmId,
    pm
  ) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(
          `${URLS.pricing}/pricing/configs/${configurationId}/price_models/${pmId}`,
          {}
        ),
        {
          headers,
          method: "PATCH",
          body: JSON.stringify(pm),
        }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      console.log(err);
      throw new ApiError(err);
    }
  });

  deletePriceModelForConfiguration = flow(function* deletePriceModelForConfiguration(
    configurationId,
    pmId
  ) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(
          `${URLS.pricing}/pricing/configs/${configurationId}/price_models/${pmId}`,
          {}
        ),
        {
          headers,
          method: "DELETE",
        }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      console.log(err);
      throw new ApiError(err);
    }
  });

  getPriceComponentsForConfiguration = flow(function* getPriceComponentsForConfiguration(
    configurationId
  ) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(`${URLS.pricing}/pricing/configs/${configurationId}/components`, {}),
        { headers }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      console.log(err);
      throw new ApiError(err);
    }
  });

  getCGroupForConfiguration = flow(function* getCGroupForConfiguration(configurationId) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(`${URLS.pricing}/pricing/configs/${configurationId}/customer_groups`, {}),
        { headers }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      console.log(err);
      throw new ApiError(err);
    }
  });

  updateCGroupForConfiguration = flow(function* updateCGroupForConfiguration(
    configurationId,
    cgroupId,
    data
  ) {
    try {
      const headers = yield this.parent.session.authHeaders();
      const res = yield fetch(
        urlWithParams(
          `${URLS.pricing}/pricing/configs/${configurationId}/customer_groups/${cgroupId}`,
          {}
        ),
        { headers, method: "PATCH", body: JSON.stringify(data) }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  deleteCGroupForConfiguration = flow(function* deleteCGroupForConfiguration(
    configurationId,
    cgroupId
  ) {
    try {
      const headers = yield this.parent.session.authHeaders();
      const res = yield fetch(
        urlWithParams(
          `${URLS.pricing}/pricing/configs/${configurationId}/customer_groups/${cgroupId}`,
          {}
        ),
        { headers, method: "DELETE" }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  createCGroupForConfiguration = flow(function* createCGroupForConfiguration(
    configurationId,
    data
  ) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(`${URLS.pricing}/pricing/configs/${configurationId}/customer_groups`, {}),
        {
          headers,
          method: "POST",
          body: JSON.stringify(data),
        }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  getComponentTypes = flow(function* getComponentTypes() {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(urlWithParams(`${URLS.pricing}/pricing/component_types`, {}), {
        headers,
      });
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  savePricingComponent = flow(function* savePricingComponent(configId, pc) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(`${URLS.pricing}/pricing/configs/${configId}/components`, {}),
        { headers, method: "POST", body: JSON.stringify(pc) }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  updatePricingComponent = flow(function* updatePricingComponent(configId, pc) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(`${URLS.pricing}/pricing/configs/${configId}/components/${pc.id}`, {}),
        { headers, method: "PATCH", body: JSON.stringify(pc) }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  deletePricingComponent = flow(function* deletePricingComponent(configId, componentId) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(`${URLS.pricing}/pricing/configs/${configId}/components/${componentId}`, {}),
        { headers, method: "DELETE" }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  attachPricingComponentToPriceModel = flow(function* attachPricingComponentToPriceModel(
    configId,
    pmId,
    pcId
  ) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(
          `${URLS.pricing}/pricing/configs/${configId}/price_models/${pmId}/components`,
          {}
        ),
        { headers, method: "POST", body: JSON.stringify({ id: pcId }) }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  attachCGRoupToPriceModel = flow(function* attachCGRoupToPriceModel(configId, pmId, pcId) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(
          `${URLS.pricing}/pricing/configs/${configId}/price_models/${pmId}/customer_groups`,
          {}
        ),
        { headers, method: "POST", body: JSON.stringify({ id: pcId }) }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  postJob = flow(function* postJob(queue, taskname, parameters) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(urlWithParams(`${URLS.pricing}/jobs/${queue}`, {}), {
        headers,
        method: "POST",
        body: JSON.stringify({ name: taskname, params: parameters }),
      });
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      console.log("submit error", resdata);
      throw new ApiDetailError(resdata);
    } catch (err) {
      console.log("post job network error", err);
      throw new ApiError(err);
    }
  });

  getJobStatus = flow(function* getJobStatus(queue, taskId) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(urlWithParams(`${URLS.pricing}/jobs/${queue}/${taskId}`, {}), {
        headers,
      });
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  getJobResult = flow(function* getJobResult(queue, taskId, params = {}) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(
        urlWithParams(`${URLS.pricing}/jobs/${queue}/${taskId}/result`, params),
        { headers }
      );
      const resdata = yield res.json();
      if (res.ok) {
        return resdata;
      }
      throw new ApiDetailError(resdata);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  getUserProfile = flow(function* getUserProfile() {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(urlWithParams(`${URLS.mdslv4}/profile`, {}), { headers });
      const resdata = yield res.json();
      if (res.ok && resdata.error === null) {
        return resdata.data;
      }
      throw new Error(resdata.error);
    } catch (err) {
      throw new Error(err);
    }
  });

  getApiResource = flow(function* getApiResource({
    source = "mdslv4",
    url,
    pathname,
    resource_id,
    query = {},
  }) {
    const headers = yield this.parent.session.authHeaders();
    let targetUrl = "";
    if (pathname) {
      targetUrl = URLMAP[pathname]({ resource_id });
    }
    if (url) {
      targetUrl = url;
    }

    try {
      const res = yield fetch(urlWithParams(URLS[source] + targetUrl, query), { headers });
      const resdata = yield res.json();
      if (res.ok && resdata.error === null) {
        return resdata.data;
      }
      throw new Error(resdata.error);
    } catch (err) {
      throw new Error(err);
    }
  });

  getApiResourceList = flow(function* getApiResourceList({
    source = "mdslv4",
    url,
    pathname,
    pathargs = {},
    query = {},
  }) {
    const headers = yield this.parent.session.authHeaders();
    let targetUrl = "";
    if (pathname) {
      targetUrl = URLMAP[pathname](pathargs);
    }
    if (url) {
      targetUrl = url;
    }

    try {
      const res = yield fetch(urlWithParams(URLS[source] + targetUrl, query), { headers });
      const resdata = yield res.json();
      if (res.ok && resdata.error === null) {
        return resdata.data.collection;
      }
      throw new Error(resdata.error);
    } catch (err) {
      throw new Error(err);
    }
  });

  createApiResource = flow(function* createApiResource({
    source = "mdslv4",
    pathname,
    pathargs = {},
    data,
    query = {},
  }) {
    const targetUrl = urlWithParams(URLS[source] + URLMAP[pathname](pathargs), query);
    const headers = yield this.parent.session.authHeaders();
    let res = null;
    res = yield fetch(targetUrl, {
      headers: { ...headers, "content-type": "application/json" },
      method: "POST",
      body: JSON.stringify(data),
    });
    let resp = { data: null };
    if (res.status === 201) {
      try {
        const respData = yield res.text();
        if (respData.length) {
          resp = JSON.parse(respData) || resp;
        }
      } catch (err) {
        console.log("no data returned");
      }
      if (resp.data) {
        return resp.data;
      }
      return resp.data ? resp.data : resp;
    }
    if (resp.hasOwnProperty("error")) {
      throw new Error(resp.error);
    } else {
      throw new Error("unable to create resource");
    }
  });

  updateApiResource = flow(function* updateApiResource({
    source = "mdslv4",
    pathname,
    pathargs = {},
    data,
    method = "PATCH",
    query = {},
  }) {
    const targetUrl = urlWithParams(URLS[source] + URLMAP[pathname](pathargs), query);
    const headers = yield this.parent.session.authHeaders();
    let res = null;
    res = yield fetch(targetUrl, {
      headers: { ...headers, "content-type": "application/json" },
      method,
      body: JSON.stringify(data),
    });
    let resp = { data: null };
    if (res.status === 200) {
      try {
        resp = yield res.json();
      } catch (err) {}
      if (resp.data) {
        return resp.data;
      }
      return resp.data ? resp.data : resp;
    }
    if (resp.hasOwnProperty("error")) {
      throw new Error(resp.error);
    } else {
      throw new Error("unable to create resource");
    }
  });

  updateNetworkData = flow(function* updateNetworkData({
    source = "mdslv4",
    pathname = "network_data",
    pathargs = {},
    data,
    method = "PUT",
    query = {},
  }) {
    const targetUrl = urlWithParams(URLS[source] + URLMAP[pathname](pathargs), query);
    const headers = yield this.parent.session.authHeaders();
    let res = null;
    res = yield fetch(targetUrl, {
      headers: { ...headers, "content-type": "application/json" },
      method,
      body: JSON.stringify(data),
    });
    const resp = { data: {} };
    if (res.status === 201) {
      return true;
    }
    if (resp.hasOwnProperty("error")) {
      throw new Error(resp.error);
    } else {
      throw new Error("unable to update data");
    }
  });

  deleteApiResource = flow(function* deleteApiResource({
    source = "mdslv4",
    pathname,
    pathargs = {},
    query = {},
  }) {
    const targetUrl = urlWithParams(URLS[source] + URLMAP[pathname](pathargs), query);
    const headers = yield this.parent.session.authHeaders();
    let res = null;
    res = yield fetch(targetUrl, { headers, method: "DELETE" });
    if (res.status === 204) {
      return true;
    }
    throw Error("unable to delete resource");
  });
}
