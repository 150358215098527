import type { MRT_ColumnDef, MRT_RowData } from "material-react-table";

export type InfoBlockColumn = {
  columns?: InfoBlockColumn[];
  muiTableHeadCellProps?: {
    "data-testid"?: string;
  } & MRT_ColumnDef<any>["muiTableHeadCellProps"];

  // our props
  sub_id?: string;
  sublabel?: string;
  info?: string;
  actions?: Array<{
    id: string;
    onClick: (value: string) => void;
    label: string;
  }>;
  align?: "left" | "right" | "center";
  disabled?: boolean;
  type?: string;
  isVisible?: boolean;
  iconMapping?: Record<string, string>;
  translateNs?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translateOptions?: any;
  onClick?: (value: string) => void;
  onChange?: (value: string) => void;
  "data-testid"?: string;
} & Omit<MRT_ColumnDef<MRT_RowData>, "muiTableHeadCellProps" | "columns">;

export type YearOption = number | string | null;
export type MonthOption = number | null;

// Constants
export const DEFAULT_ROW_PER_PAGE = 25;
export const MIN_COLUMN_WIDTH = 240;
