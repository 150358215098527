/* eslint-disable no-restricted-syntax */
import { useCallback, useEffect } from "react";
import { action } from "mobx";
import { useLocalObservable } from "mobx-react";
import { Info } from "luxon";

import { getValueFromBlock, SUBSTATION_BLOCK_TYPES as SBT } from "@conf/blocks";
import { COLUMN_PERIOD } from "@conf/Columns";
import { isValue, range } from "@core/utils";
import { useInject, useReaction } from "@hooks";

import { SUBSTATION } from "../../SubList/constant";

const DURATIONS = [
  { value: "7", label: "7 days" },
  { value: "14", label: "14 days" },
  { value: "30", label: "30 days" },
];

const BLOCKS = {
  energy: "heat_energy_sum",
  returnTemp: "returntemp_flowweighted_avg",
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const useOverview = ({ type }) => {
  const { networks, sub, newapi } = useInject("networks", "sub", "newapi");

  const local = useLocalObservable(() => ({
    refetch: false,
    pending: false,
    year: networks.lpYear?.year,
    activePeriod: COLUMN_PERIOD.year,
    selectDuration: DURATIONS[0].value,
    infoBlockData: {},
    error: null,

    get resourceId() {
      return type === SUBSTATION ? sub.current_substation : sub.current_cluster.id;
    },

    get requiredYears() {
      const requiredYears = [];
      const lpYear = networks.LastProcessedYear;
      requiredYears.push(lpYear.year);
      requiredYears.push(lpYear.plus({ years: -1 }).year);

      // last 5 year
      for (const yearsBack of range(4, -1, -1)) {
        const cYear = lpYear.plus({ years: -1 * yearsBack }).year;
        requiredYears.push(cYear);
      }
      requiredYears.sort();

      return [...new Set(requiredYears)];
    },

    get requiredMonths() {
      const lpMonth = networks.LastProcessedMonth;
      const lpYear = networks.LastProcessedYear;
      const reqMonths = [];
      for (const monthsBack of range(0, 12)) {
        const cMonth = lpMonth.plus({ months: -1 * monthsBack });
        reqMonths.push({ epyear: lpYear.year, year: cMonth.year, month: cMonth.month });
      }
      return reqMonths;
    },

    get blockNames() {
      const SBTBlocks = [
        SBT.install_address.to_block_name(),
        SBT.building.to_block_name(),
        SBT.customer.to_block_name(),
        SBT.core.to_block_name({ year: networks.lpYear.year }),
      ];

      for (const cYear1 of local.requiredYears) {
        SBTBlocks.push(SBT.core.to_block_name({ year: cYear1, month: null }));
        SBTBlocks.push(SBT.epcore_normalized.to_block_name({ year: cYear1, month: 12 }));
      }

      for (const cMonth1 of local.requiredMonths) {
        SBTBlocks.push(SBT.core.to_block_name({ year: cMonth1.year, month: cMonth1.month }));
        SBTBlocks.push(
          SBT.epcore_normalized_monthly.to_block_name({
            syear: cMonth1.epyear,
            smonth: 12,
            month: cMonth1.month,
          })
        );
      }

      return [...new Set(SBTBlocks)];
    },
    handleSelectDuration: action((e) => {
      local.selectDuration = e.target.value;
    }),
    fetchApi: action(async () => {
      if (local.pending) return;
      local.pending = true;
      try {
        const result = await newapi.getInfoBlocksV4({
          resource_id: local.resourceId,
          resource_type: type,
          network_id: networks.current_network.uid,
          block_names: local.blockNames,
        });
        local.infoBlockData = result;
        local.pending = false;
      } catch (error) {
        local.error = error;
        local.pending = false;
      }
    }),
  }));

  useEffect(() => {
    void local.fetchApi();
  }, []);

  useReaction(
    () => [local.resourceId],
    () => {
      local.refetch = true;
    }
  );

  useEffect(() => {
    if (!local.pending) local.refetch = false;
  }, [local.pending, local]);

  const fetching = local.refetch || local.pending;

  const getBlockData = useCallback(
    (BLOCK_TYPE) => {
      const lpYear = networks.LastProcessedYear;
      const data = { current: 0, previous: 0 };
      if (fetching) return data;

      for (const yearsBack of range(0, 2)) {
        const cYear = lpYear.plus({ years: -1 * yearsBack });
        const block = SBT.epcore_normalized.to_block_name({ year: cYear.year, month: 12 });
        const dBlock = local.infoBlockData[block];
        let val = null;
        if (dBlock) {
          const rowId = dBlock.idxMap.get(local.resourceId);
          const row = dBlock.data[rowId];
          const colId = dBlock.columns[BLOCK_TYPE].idx;
          val = row[colId];
        }
        if (isValue(val)) {
          if (yearsBack === 0) {
            data.current += val;
          } else {
            data.previous += val;
          }
        }
      }
      return data;
    },
    [networks.LastProcessedYear, fetching, local.infoBlockData, local.resourceId]
  );

  const getMonthlyData = useCallback(
    (BLOCK_TYPE) => {
      const reading = { first: [], second: [], months: [] };
      for (const cMonth of local.requiredMonths) {
        const coreBlock = SBT.core.to_block_name({ year: cMonth.year, month: cMonth.month });
        const epBlock = SBT.epcore_normalized_monthly.to_block_name({
          syear: cMonth.epyear,
          smonth: 12,
          month: cMonth.month,
        });
        const coreValue = getValueFromBlock(
          local.infoBlockData,
          coreBlock,
          local.resourceId,
          BLOCK_TYPE
        );
        const epCoreValue = getValueFromBlock(
          local.infoBlockData,
          epBlock,
          local.resourceId,
          BLOCK_TYPE
        );
        const monthVal = Info.months("short")[cMonth.month - 1];
        reading.second.unshift(coreValue);
        reading.first.unshift(epCoreValue);
        reading.months.unshift(monthVal);
      }

      return reading;
    },
    [local.infoBlockData, local.requiredMonths, local.resourceId]
  );

  const getYearlyData = useCallback(
    (BLOCK_TYPE) => {
      const reading = { first: [], second: [], years: [] };
      for (const cYear of local.requiredYears) {
        const coreBlock = SBT.core.to_block_name({ year: cYear, month: null });
        const epBlock = SBT.epcore_normalized.to_block_name({ year: cYear, month: 12 });
        const coreValue = getValueFromBlock(
          local.infoBlockData,
          coreBlock,
          local.resourceId,
          BLOCK_TYPE
        );
        const epCoreValue = getValueFromBlock(
          local.infoBlockData,
          epBlock,
          local.resourceId,
          BLOCK_TYPE
        );

        appendYearlyReadings(reading, cYear, epCoreValue, coreValue);
      }
      return reading;
    },
    [local.infoBlockData, local.requiredYears, local.resourceId]
  );

  const calculateBlockValue = (blockData, currentValue) => (blockData ? currentValue : 0);

  const calculateBlockTrend = (blockData, currentVal, prevVal) =>
    blockData?.previous ? ((currentVal - prevVal) / prevVal) * 100 : null;

  const energyConsumption = getBlockData(BLOCKS.energy);
  const averageReturnTemp = getBlockData(BLOCKS.returnTemp);

  // consumption
  const consumptionValue = calculateBlockValue(energyConsumption, energyConsumption?.current);
  const consumptionTrend = calculateBlockTrend(
    energyConsumption,
    energyConsumption?.current,
    energyConsumption?.previous
  );
  const monthlyConsumption = getMonthlyData(BLOCKS.energy);
  const yearlyConsumption = getYearlyData(BLOCKS.energy);

  // return temperature
  const returnTempValue = calculateBlockValue(averageReturnTemp, averageReturnTemp?.current);
  let returnTempTrend = 0;
  if (averageReturnTemp?.current && averageReturnTemp?.previous) {
    returnTempTrend = averageReturnTemp.current - averageReturnTemp.previous;
  }

  const monthlyReturnTemp = getMonthlyData(BLOCKS.returnTemp);
  const yearlyReturnTemp = getYearlyData(BLOCKS.returnTemp);

  function isDataExist(data) {
    return data ? !!data : {};
  }

  return {
    consumption: {
      consumptionValue,
      consumptionTrend,
      monthlyConsumption,
      yearlyConsumption,
      energyConsumption,
    },
    blockData: {
      infoBlockData: local.infoBlockData,
      infoBlockError: local.error,
      isDataExist,
    },
    temp: {
      returnTempValue,
      returnTempTrend,
      yearlyReturnTemp,
      monthlyReturnTemp,
      averageReturnTemp,
    },
    fetching,
  };
};

export function appendYearlyReadings(reading, year, epCoreValue, coreValue) {
  if (reading.years.length === 0 && !coreValue && !epCoreValue) {
    // If no values exist at all the year should be skipped and not drawn, if no values have not been added for years before that
    return;
  }

  reading.years.push(year);
  reading.second.push(coreValue);
  reading.first.push(epCoreValue);
}

export default useOverview;
