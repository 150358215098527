import { action, computed, flow, makeObservable, observable } from "mobx";
import { DateTime } from "luxon";

import { DATETIME_FORMAT_ISO_SHORT_FIXED_SECONDS } from "@conf/constants";
import { logger } from "@core/logger";

import { ConfigViewTab, DefaultConfTab, JobStatus, ParameterType } from "./Opt.const";

const log = logger.getSubLogger({ name: "opt.store" });

function defaultDateToIsoShortened(added_hours) {
  // No minutes, seconds or milliseconds
  const now = DateTime.local().plus({ hours: added_hours });
  return now.minus({ minutes: now.minute });
}

const DEFAULT_START = defaultDateToIsoShortened(0);
const DEFAULT_STOP = defaultDateToIsoShortened(168);

// noinspection JSPotentiallyInvalidUsageOfClassThis
class OptimizationStore {
  jobStatus = JobStatus.NOT_STARTED;

  opt_result = null;

  fetching = false;

  loading = false;

  saving_operational_plan = false;

  fetch_start_date = defaultDateToIsoShortened(0);

  fetch_stop_date = defaultDateToIsoShortened(168 - 6);

  training_start_date = null;

  training_stop_date = null;

  current_network = null;

  networkComponents = [];

  configurations = [];

  savedSimulations = [];

  conf_page_no = 0;

  configRowPerPage = 10;

  current_conf = null;

  confDetailTab = ConfigViewTab.overview;

  confDefaultSettingsTab = DefaultConfTab.components;

  current_comp = null;

  current_event = null;

  changeable_event = null;

  fetch_noda = false;

  send_noda = false;

  operational_plan = null;

  componentTypes = [];

  configId = -1;

  notificationStore = null;

  previousJobId = null;

  stateInputSource = "manual";

  constructor(parent) {
    makeObservable(this, {
      deleteSavedSimulation: true,
      setStateInputStates: true,
      opt_result: true,
      networkComponents: observable,
      componentTypes: true,
      notificationStore: true,
      loadOptimizationStatus: true,
      fetchOptimization: true,
      getFormattedStateInput: true,
      loadOperationalPlan: true,
      saveOperationalPlan: action.bound,
      createNewConfig: true,
      deleteConfig: true,
      copyConfig: true,
      loadNetworkComponents: true,
      loadDeviationsForConfig: flow.bound,
      createDeviationEvent: true,
      loadEvents: flow.bound,
      saveEvent: true,
      deleteEvent: true,
      updateDeviationProperty: true,
      upsertDefaultPropertyValue: true,
      loadComponentTypes: true,
      getCurrentNetworkId: true,
      getCurrentNetworkName: true,
      // Saved Sims
      savedSimulations: observable,
      loadSavedSimulations: flow.bound,
      fetchSavedSimulationResults: flow.bound,
      // Configs
      configurations: observable,
      configId: observable,
      loadConfigurations: true,
      previousJobId: observable,
      hasPreviousJob: computed, // Calculated from previousJobId
      jobStatus: observable,
      saveResults: flow.bound,
      fetching: observable,
      loading: observable,
      saving_operational_plan: observable,
      fetch_start_date: observable,
      fetch_stop_date: observable,
      training_start_date: observable,
      training_stop_date: observable,
      current_network: observable,
      conf_page_no: observable,
      configRowPerPage: observable,
      current_conf: observable, // TODO: Remove and use computed
      selectedConfig: computed,
      fetchResult: flow.bound,
      // Component Observables
      createComponent: flow.bound,
      deleteComponent: flow.bound,
      updateComponent: flow.bound,
      updateComponentsSortOrder: flow.bound,
      updateSavedSimulation: flow.bound,
      confDetailTab: observable,
      confDefaultSettingsTab: observable,
      current_comp: observable,
      current_event: observable,
      changeable_event: observable,
      fetch_noda: observable,
      send_noda: observable,
      stateInputSource: observable,
      operational_plan: observable,
      setCurrentNetwork: action.bound,
      resetToDefaults: action.bound,
      updateFetchStartDate: action.bound,
      updateFetchEndDate: action.bound,
      updateTrainingStartDate: action.bound,
      updateTrainingEndDate: action.bound,
      selectConf: action.bound,
      selectComponent: action.bound,
      selectEvent: action.bound,
      cancelEventChanges: action.bound,
      changeRowsPerPage: action.bound,
      changePageNo: action.bound,
      changeConfDetailTab: action.bound,
      changeDefaultSettingsTab: action.bound,
      updateInputState: action.bound,
      updateStateInputSource: action.bound,
      hasEmptyStartState: computed,
      clickFetchNoda: action.bound,
      clickSendNoda: action.bound,
      saveConfigurationSettings: action.bound,
    });

    this.parent = parent;
    this.notificationStore = parent.notifications;
  }

  setCurrentNetwork(network) {
    this.current_network = network;
  }

  resetToDefaults() {
    log.debug("resetToDefaults");
    // Time for the training date should always be 00:00
    const networkNow = DateTime.local().startOf("day");
    // Training dates should always be latest 18 month,
    // but with a delay of 7 days (due to lag in data upload)
    const trainingStopDate = networkNow.minus({ days: 7 });
    this.training_start_date = trainingStopDate.minus({ month: 18 });
    this.training_stop_date = trainingStopDate;

    this.fetch_start_date = DEFAULT_START;
    this.fetch_stop_date = DEFAULT_STOP;

    this.fetching = false;
    this.saving_operational_plan = false;
    // Check if a job id is stored in the local storage
    const jobId = localStorage.getItem("optJobID");
    const oldJobNetworkId = localStorage.getItem("optJobNetworkId");
    // Only set previous job id if the network id matches the current network id
    this.previousJobId = oldJobNetworkId === this.getCurrentNetworkId() ? jobId : null;
  }

  get hasPreviousJob() {
    return !!this.previousJobId && this.previousJobId !== this.opt_result?.meta_data?.task_id;
  }

  updateFetchStartDate(start_date) {
    this.fetch_start_date = start_date;
  }

  updateFetchEndDate(end_date) {
    this.fetch_stop_date = end_date;
  }

  updateStateInputSource(value) {
    this.stateInputSource = value;
  }

  updateTrainingStartDate(start_date) {
    this.training_start_date = start_date.startOf("day");
  }

  updateTrainingEndDate(end_date) {
    this.training_stop_date = end_date.startOf("day");
  }

  selectConf(config) {
    this.current_comp = null;
    this.current_event = null;
    if (config) {
      this.current_conf = config;
      this.configId = config.id;
    } else {
      this.current_conf = null;
      this.configId = -1;
    }
  }

  get selectedConfig() {
    this.loading = true;
    if (this.configId === -1) {
      this.loading = false;
      return null;
    }
    this.loading = false;
    return this.configurations.find((c) => c.id === this.configId);
  }

  selectComponent(component) {
    this.current_comp = JSON.parse(JSON.stringify(component));
    this.current_event = null;
  }

  selectEvent(event) {
    this.current_event = event;
    this.changeable_event = JSON.parse(JSON.stringify(this.current_event));
  }

  cancelEventChanges() {
    this.changeable_event = JSON.parse(JSON.stringify(this.current_event));
  }

  changeRowsPerPage(event) {
    const { value } = event.target;
    log.debug(`new "rows per page": ${value}`);
    this.configRowPerPage = Number.parseInt(value);
    this.conf_page_no = 0;
  }

  changePageNo(e, value) {
    log.debug(`new "page no": ${value}`);
    this.conf_page_no = 1;
    this.conf_page_no = value;
  }

  changeConfDetailTab(tab) {
    if (this.confDetailTab === tab) return;
    const configViews = Object.values(ConfigViewTab);
    if (!configViews.includes(tab)) {
      log.error(
        `Invalid tab value provided for the config view: "${tab}" Valid options; ${configViews.join(
          ", "
        )}`
      );
      return;
    }
    log.info("Config tab changed to", tab);
    this.confDetailTab = tab;
  }

  changeDefaultSettingsTab(tab) {
    this.confDefaultSettingsTab = tab;
  }

  updateInputState(configId, state) {
    this.loading = true;
    const config = this.configurations.find((c) => c.id === configId);
    const comp = config.components.find((c) => c.id === state.compId);
    const prop = comp.properties.find((p) => p.id === state.propId);
    prop.start_state = state.startState;
    prop.end_state = state.endState;
    const configIndex = this.configurations.findIndex((c) => c.id === configId);
    this.configurations[configIndex] = config;
    this.loading = false;
  }

  clickFetchNoda() {
    this.fetch_noda = !this.fetch_noda;
  }

  clickSendNoda() {
    this.send_noda = !this.send_noda;
  }

  loadOptimizationStatus = flow(function* () {
    this.opt_result = yield this.parent.opt_api.getOptimizationStatus();
  });

  fetchOptimization = flow(function* () {
    const simStartDate = DateTime.fromISO(this.fetch_start_date);
    const simEndDate = DateTime.fromISO(this.fetch_stop_date);
    const trainStartDate = DateTime.fromISO(this.training_start_date);
    const trainEndDate = DateTime.fromISO(this.training_stop_date);

    const diffOnSimulationDate = simEndDate.diff(simStartDate, ["days", "hours"]).toObject();
    const diffOnTrainingDate = trainEndDate.diff(trainStartDate, ["days", "hours"]).toObject();
    const currentMonthTs = DateTime.local().endOf("month").ts;

    if (diffOnSimulationDate.days > 15) {
      this.notificationStore.error("Simulation: Date span cannot be more than 2 weeks");
      return;
    }
    if (diffOnSimulationDate.hours < 0 || diffOnSimulationDate.days < 0) {
      this.notificationStore.error("Simulation: Start date must be before end date");
      return;
    }
    if (diffOnTrainingDate.hours < 0 || diffOnTrainingDate.days < 0) {
      this.notificationStore.error("Training: Start date must be before end date");
      return;
    }

    if (this.hasEmptyStartState && this.stateInputSource === "manual") {
      this.notificationStore.error("All state input rows need a start state");
      return;
    }

    if (
      this.training_start_date.ts > currentMonthTs ||
      this.training_stop_date.ts > currentMonthTs
    ) {
      this.notificationStore.error("Training: Date should not be over current month");
      return;
    }

    this.fetching = true;
    try {
      // Start the simulation
      const jobID = yield this.parent.opt_api.startSimulation({
        network_name: this.getCurrentNetworkName(),
        network_id: this.getCurrentNetworkId(),
        start_time: this.fetch_start_date,
        stop_time: this.fetch_stop_date,
        training_start: this.training_start_date,
        training_stop: this.training_stop_date,
        config_id: this.configId,
        state_input: this.getFormattedStateInput(),
        fetch_flex: this.fetch_noda,
        send_flex: false,
        make_official: false,
        is_scenario: false,
        // This is just a way to disable validation of the state input, but still send it to the backend
        state_input_source: this.stateInputSource === "custom" ? "manual" : this.stateInputSource,
      });
      log.debug(`Simulation started with jobID: ${jobID}`);
      // Set the jobID in the local storage
      localStorage.setItem("optJobID", jobID);
      localStorage.setItem("optJobNetworkId", this.getCurrentNetworkId());
      // Set job status to running
      this.jobStatus = JobStatus.RUNNING;
      yield this.fetchResult(jobID);
    } catch (err) {
      localStorage.removeItem("optJobID");
      this.fetching = false;
      this.opt_result = null;
      this.notificationStore.error(`Simulation Failed: ${err.message}`);
      log.error(err.message);
    }
  });

  fetchResult = flow(function* (jobId) {
    if (jobId) {
      // Poll the job status
      let error = null;
      try {
        while (this.jobStatus !== JobStatus.done && this.jobStatus !== JobStatus.error) {
          this.fetching = true;
          const jobStatus = yield this.parent.opt_api.getJobStatus(jobId);
          log.debug(`Job status: ${jobStatus.status}`);
          this.jobStatus = JobStatus[jobStatus.status];
          error = jobStatus.error;

          // if any error occurs, break the loop
          if (error) {
            break;
          }
          // Wait 2 seconds before polling again
          yield new Promise((resolve) => {
            setTimeout(resolve, 2000);
          });
        }
        // If the job failed, throw an error
        if (this.jobStatus === JobStatus.error || error) {
          this.notificationStore.error(error || "Job failed");
          this.fetching = false;
          log.error(error || "Job failed");
          return;
        }
        // Fetch the result
        this.opt_result = yield this.parent.opt_api.getSimulationResult(jobId);
        this.configId = this.opt_result.input_parameters.config_id;
        this.setStateInputStates(this.configId, this.opt_result.input_parameters.state_input);
        this.notificationStore.success("Simulation result fetched");
        this.fetching = false;
      } catch (err) {
        localStorage.removeItem("optJobID");
        this.notificationStore.error(`Error fetching simulation result: ${err.toString()}`);
        this.fetching = false;
        log.error(err);
      }
    }
  });

  saveResults = flow(function* (simulation_name) {
    try {
      log.debug("Saving results...", this.opt_result);
      yield this.parent.opt_api.saveSimulationResult(
        this.getCurrentNetworkId(),
        this.opt_result.meta_data.task_id,
        simulation_name
      );
      this.notificationStore.success("Results saved");
      // Refetch saved simulations minimal
      yield this.loadSavedSimulations();
    } catch (err) {
      this.notificationStore.error(`Error saving results: ${err.toString()}`);
      log.error(err);
    }
  });

  deleteSavedSimulation = flow(function* (simulationId) {
    try {
      yield this.parent.opt_api.deleteSavedSimulation(this.getCurrentNetworkId(), simulationId);
      this.savedSimulations = this.savedSimulations.filter((sim) => sim.id !== simulationId);
      this.notificationStore.success("Saved simulation deleted");
    } catch (err) {
      this.notificationStore.error(err.toString());
      log.error(err);
    }
  });

  updateSavedSimulation = flow(function* (simulationId, simulationName) {
    try {
      yield this.parent.opt_api.updateSavedSimulation(
        this.getCurrentNetworkId(),
        simulationId,
        simulationName
      );

      // Refetch saved simulations minimal
      yield this.loadSavedSimulations();
      this.notificationStore.success("Saved simulation updated");
    } catch (err) {
      this.notificationStore.error(err.toString());
      log.error(err);
    }
  });

  get hasEmptyStartState() {
    const config = this.selectedConfig;
    if (!config) {
      return false;
    }
    return config.components.some((component) =>
      component.properties.some((prop) => {
        if (prop.type === ParameterType.STATE_INPUT) {
          // If the start state is empty, or doesn't exist, return true
          return !prop.start_state || prop.start_state === "";
        }
        return false;
      })
    );
  }

  getFormattedStateInput() {
    if (this.configId === -1) {
      return [];
    }
    return this.configurations
      .filter((config) => config.id === this.configId)
      .reduce((acc, config) => {
        config.components.forEach((comp) => {
          comp.properties
            .filter((prop) => prop.type === ParameterType.STATE_INPUT)
            .forEach((prop) => {
              acc.push({
                comp_id: comp.id,
                prop_id: prop.id,
                start_state: prop.start_state,
                end_state: prop.end_state,
              });
            });
        });
        return acc;
      }, []);
  }

  setStateInputStates(configId, formattedStateInput) {
    const config = this.configurations.find((c) => c.id === configId);
    config.components.forEach((comp) => {
      comp.properties
        .filter((prop) => prop.type === ParameterType.STATE_INPUT)
        .forEach((prop) => {
          const stateInput = formattedStateInput.find(
            (si) => si.comp_id === comp.id && si.prop_id === prop.id
          );
          if (stateInput) {
            prop.start_state = stateInput.start_state;
            prop.end_state = stateInput.end_state;
          }
        });
    });
  }

  loadOperationalPlan = flow(function* loadOperationalPlan() {
    log.debug("Fetching operational plan...");
    this.loading = true;
    try {
      const result = yield this.parent.opt_api.getOperationalPlan(this.getCurrentNetworkId());
      if (result == null) {
        this.operational_plan = null;
        return;
      }
      this.operational_plan = result;
      log.debug("Operational Plan fetched: ", result);
    } catch (err) {
      this.notificationStore.error(`Error during loading operational plan: ${err.toString()}`);
      log.error(err);
      throw err;
    }
    this.loading = false;
  });

  saveOperationalPlan = flow(function* saveOperationalPlan() {
    const { start_time, stop_time } = this.opt_result.input_parameters;
    this.saving_operational_plan = true;
    try {
      const opJobId = yield this.parent.opt_api.startSimulation({
        network_name: this.getCurrentNetworkName(),
        network_id: this.getCurrentNetworkId(),
        start_time,
        stop_time,
        training_start: this.training_start_date,
        training_stop: this.training_stop_date,
        config_id: this.configId,
        state_input: this.getFormattedStateInput(),
        fetch_flex: this.fetch_noda,
        send_flex: this.send_noda,
        make_official: true,
        is_scenario: false,
      });
      // Set the jobID in the local storage
      localStorage.setItem("optJobID", opJobId);
      localStorage.setItem("optJobNetworkId", this.getCurrentNetworkId());
      // Set job status to running
      this.jobStatus = JobStatus.RUNNING;
      // Fetch the result
      yield this.fetchResult(opJobId);
      // Refetch saved simulations minimal
      yield this.loadSavedSimulations();
      this.notificationStore.success("Operational plan saved");
      this.saving_operational_plan = false;
    } catch (err) {
      localStorage.removeItem("optJobID");
      this.saving_operational_plan = false;
      log.error(err);
      throw err;
    }
  });

  loadConfigurations = flow(function* loadConfigurations() {
    log.debug("Fetching configurations...");
    this.loading = true;
    try {
      const fetchedConfig = yield this.parent.opt_api.getOptConfigurations(
        this.getCurrentNetworkId()
      );
      // Sort configurations by id
      this.configurations = fetchedConfig.sort((a, b) => a.id - b.id);
      // Components for each configuration is network components
      this.configurations.forEach((config) => {
        config.components = this.networkComponents;
      });

      log.debug(`${this.configurations.length} Configurations fetched`);
    } catch (err) {
      this.notificationStore.error(`Error during loading configurations: ${err.toString()}`);
      log.error(err);
    }
    this.loading = false;
  });

  loadSavedSimulations = flow(function* loadSavedSimulations() {
    log.debug("Fetching saved simulations...");
    this.loading = true;
    try {
      // We initially fetch the minimal data for the saved sims, since they can be quite big
      const fetchedSimulations = yield this.parent.opt_api.getSavedSimulationsMinimal(
        this.getCurrentNetworkId()
      );
      // Sort saved sims by id
      this.savedSimulations = fetchedSimulations.sort((a, b) => a.id - b.id);
      log.debug(`${this.savedSimulations.length} Saved simulations fetched`);
    } catch (err) {
      this.notificationStore.error(`Error during loading saved simulations: ${err.toString()}`);
      log.error(err);
    }
    this.loading = false;
  });

  fetchSavedSimulationResults = flow(function* fetchSavedSimulationResults(simulationId) {
    log.debug("Fetching saved simulation results...");
    this.loading = true;
    try {
      const fetchedSimulation = yield this.parent.opt_api.getSavedSimulationResults(
        this.getCurrentNetworkId(),
        simulationId
      );
      this.savedSimulations = this.savedSimulations.map((sim) => {
        if (sim.id === simulationId) {
          return fetchedSimulation;
        }
        return sim;
      });
      log.debug("Saved simulation results fetched");
    } catch (err) {
      this.notificationStore.error(
        `Error during fetching saved simulation results: ${err.toString()}`
      );
      log.error(err);
    }
    this.loading = false;
  });

  createNewConfig = flow(function* createNewConfig() {
    try {
      const createdConfig = yield this.parent.opt_api.createNewOptConfig(
        this.getCurrentNetworkId()
      );
      if (createdConfig != null && createdConfig.id != null) {
        this.notificationStore.success("Configuration created");
        this.loadConfigurations();
      } else {
        this.notificationStore.error("Error creating new configuration");
      }
    } catch (err) {
      this.notificationStore.error(`Error creating new configuration: ${err.toString()}`);
      log.error(err);
    }
  });

  deleteConfig = flow(function* deleteConfig(config_id) {
    try {
      yield this.parent.opt_api.deleteConfig(this.getCurrentNetworkId(), config_id);
      yield this.loadConfigurations();
    } catch (err) {
      this.notificationStore.error(`Error deleting configuration: ${err.toString()}`);
      log.error(err);
    }
  });

  copyConfig = flow(function* copyConfig(config_id) {
    try {
      yield this.parent.opt_api.copyOptConfig(this.getCurrentNetworkId(), config_id);
      this.notificationStore.success("Configuration copied");
      yield this.loadConfigurations();
    } catch (err) {
      this.notificationStore.error(`Error copying configuration: ${err.toString()}`);
      log.error(err);
    }
  });

  saveConfigurationSettings = flow(function* saveConfigurationSettings(config) {
    yield this.parent.opt_api
      .saveOptConfig(this.getCurrentNetworkId(), config)
      .then((val) => {
        this.notificationStore.success("Configuration saved");
        return val;
      })
      .catch((err) => this.notificationStore.error(`Error saving configuration: ${err.message}`));
    yield this.loadConfigurations();
  });

  loadNetworkComponents = flow(function* () {
    this.loading = true;
    try {
      log.debug("Fetching network components...");
      yield this.parent.opt_api
        .getNetworkComponents(this.getCurrentNetworkId())
        .then((components) => {
          this.networkComponents = components;
          return components;
        });
      log.debug(`${this.networkComponents.length} Network components fetched`);

      // Set the components for each configuration
      this.configurations.forEach((c) => {
        // Opt configs all have the same components
        c.components = this.networkComponents;
      });

      // Get network settings
      const networkSettings = yield this.parent.opt_api.getNetworkSettings(
        this.getCurrentNetworkId()
      );
      log.debug("Network settings fetched", networkSettings);
    } catch (err) {
      this.notificationStore.error(`Error loading network components: ${err.toString()}`);
      log.error(err);
    }
    this.loading = false;
  });

  loadDeviationsForConfig = flow(function* () {
    this.loading = true;
    try {
      log.debug("Fetching deviations...");
      this.current_conf.deviations = yield this.parent.opt_api.getDeviationsForConfig(
        this.getCurrentNetworkId(),
        this.current_conf.id
      );
      log.debug(`${this.current_conf.deviations.length} Deviations fetched`);
    } catch (err) {
      this.notificationStore.error(`Error loading deviations for config: ${err.toString()}`);
      log.error(err);
    }
    this.loading = false;
  });

  createDeviationEvent = flow(function* () {
    try {
      const createdEvent = yield this.parent.opt_api.createDeviationEvent(
        this.getCurrentNetworkId(),
        this.current_conf.id,
        this.current_comp.id
      );
      if (createdEvent != null) {
        // this can be removed if backend initially created the start_date with DATETIME_FORMAT_ISO_SHORT format
        createdEvent.start_date = DateTime.fromISO(createdEvent.start_date).toFormat(
          DATETIME_FORMAT_ISO_SHORT_FIXED_SECONDS
        );
        this.current_comp.events.unshift(createdEvent);
      }
      this.notificationStore.success("Deviation event created successfully");
    } catch (err) {
      this.notificationStore.error(`Error creating deviation event: ${err.toString()}`);
      log.error(err);
    }
  });

  loadEvents = flow(function* () {
    try {
      this.current_comp.events = yield this.parent.opt_api.getEventsForComponent(
        this.getCurrentNetworkId(),
        this.current_conf.id,
        this.current_comp.id
      );
    } catch (err) {
      this.notificationStore.error(`Error loading events: ${err.toString()}`);
      log.error(err);
    }
  });

  saveEvent = flow(function* () {
    try {
      const newEvent = JSON.parse(JSON.stringify(this.changeable_event));

      yield this.parent.opt_api.saveEvent(
        this.getCurrentNetworkId(),
        this.current_conf.id,
        this.current_comp.id,
        newEvent
      );
      this.current_event = JSON.parse(JSON.stringify(this.changeable_event));

      this.selectEvent(this.changeable_event);
      this.loadEvents();
      // Update event on the current config
      const newEvents = this.current_conf.deviations.filter(
        (d) => d.event_id !== this.current_event.id
      );
      newEvents.push(this.current_event);
      this.current_conf.deviations = newEvents;
      this.opt_result = null;
    } catch (err) {
      this.notificationStore.error(`Error saving event: ${err.message}`);
      log.error(err);
    }
  });

  deleteEvent = flow(function* () {
    try {
      yield this.parent.opt_api.deleteEvent(
        this.getCurrentNetworkId(),
        this.current_conf.id,
        this.current_event
      );
      this.current_comp.events = this.current_comp.events.filter(
        (e) => e.id !== this.current_event.id
      );
      this.current_event = null;
      this.changeable_event = null;
      this.opt_result = null;
    } catch (err) {
      this.notificationStore.error(`Error deleting event: ${err.toString()}`);
      log.error(err);
    }
  });

  updateDeviationProperty = flow(function* (property_id, new_value) {
    try {
      if (new_value === "" || new_value === null) {
        yield this.parent.opt_api.deleteDeviationProperty(
          this.getCurrentNetworkId(),
          this.current_event.id,
          property_id
        );
        this.current_conf.deviations = this.current_conf.deviations.filter(
          (d) => !(d.event_id === this.current_event.id && d.property_id === property_id)
        );
      } else {
        const deviation = yield this.parent.opt_api.upsertDeviationProperty(
          this.getCurrentNetworkId(),
          this.current_event.id,
          property_id,
          new_value
        );
        this.current_conf.deviations = this.current_conf.deviations.filter(
          (d) => !(d.event_id === this.current_event.id && d.property_id === property_id)
        );
        this.current_conf.deviations.push(deviation);
      }
      this.opt_result = null;
    } catch (err) {
      this.notificationStore.error(`Error updating deviation property: ${err.toString()}`);
      log.error(err);
    }
  });

  upsertDefaultPropertyValue = flow(function* (property, new_value) {
    try {
      if (new_value === null || new_value === "") {
        return;
      }
      yield this.parent.opt_api.upsertDefaultPropertyValue(
        this.getCurrentNetworkId(),
        this.current_comp.id,
        property.id,
        new_value
      );
      property.value = new_value;
    } catch (err) {
      this.notificationStore.error(`Error updating default property value: ${err.toString()}`);
      log.error(err);
    }
  });

  loadComponentTypes = flow(function* () {
    try {
      yield this.parent.opt_api.getComponentTypes(this.getCurrentNetworkId()).then((types) => {
        this.componentTypes = types;
        return types;
      });
    } catch (err) {
      this.notificationStore.error(`Error loading component types: ${err.toString()}`);
      log.error(err);
    }
  });

  createComponent = flow(function* createComponent(component) {
    this.loading = true;
    try {
      yield this.parent.opt_api.createOptimizationComponent(this.getCurrentNetworkId(), component);

      yield this.loadNetworkComponents();

      // TODO:  This is not working, the new component is not returned from the API
      // this.networkComponents.push(result);
      this.notificationStore.success("Component created");
    } catch (err) {
      this.notificationStore.error(`Error saving component: ${err.error}`);
    }
    this.loading = false;
  });

  updateComponent = flow(function* updateComponent(component) {
    this.loading = true;
    try {
      yield this.parent.opt_api.updateOptimizationComponent(this.getCurrentNetworkId(), component);
      yield this.loadNetworkComponents();
      // Update the config components
      // TODO: This is not working, the updated component is not returned from the API
      // const index = this.networkComponents.findIndex((c) => c.id === result.id);
      // this.networkComponents[index] = result;
      this.notificationStore.success("Component updated");
    } catch (err) {
      this.notificationStore.error(`Error updating component: ${err.error}`);
      throw err;
    }
    this.loading = false;
  });

  updateComponentsSortOrder = flow(function* updateComponentsSortOrder(components) {
    this.loading = true;
    try {
      yield this.parent.opt_api
        .updateComponentsSortOrder(this.getCurrentNetworkId(), this.configId, components)
        .then(() => {
          this.networkComponents = components;
          return components;
        });
      this.notificationStore.success("Sort order updated");
    } catch (err) {
      this.notificationStore.error(`Error updating component sort order: ${err.error}`);
      throw err;
    } finally {
      // Update the config components
      const configIndex = this.configurations.findIndex((c) => c.id === this.configId);
      this.configurations[configIndex].components = this.networkComponents;
      this.loading = false;
    }
  });

  deleteComponent = flow(function* deleteComponent(component) {
    this.loading = true;
    try {
      yield this.parent.opt_api.deleteOptimizationComponent(
        this.getCurrentNetworkId(),
        component.id
      );

      // TODO: This is not working, the deleted component is not returned from the API
      // const index = this.networkComponents.findIndex((c) => c.id === result.id);
      // this.networkComponents = this.networkComponents.splice(index, 1);
      this.networkComponents = this.networkComponents.filter((c) => c.id !== component.id);
      this.notificationStore.success("Component deleted");
    } catch (err) {
      this.notificationStore.error(`Error deleting component: ${err.error}`);
    }
    this.loading = false;
  });

  getCurrentNetworkId() {
    return this.current_network?.uid;
  }

  getCurrentNetworkName() {
    return this.current_network.name;
  }
}

export default OptimizationStore;
