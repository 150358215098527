import { action, flow, makeObservable, observable } from "mobx";
import { Info } from "luxon";

import { blkReader, BLOCK, getValueFromBlock, SUBSTATION_BLOCK_TYPES as SBT } from "@conf/blocks";
import { costSavingPotential } from "@core/logics";
import { have_blocks, range, sortRows } from "@core/utils";

class Store {
  trendsConsumptionUIState = "init";

  resource_id = null;
  resource_type = "cluster";
  retGraphs = "init";
  SavingPotUIState = "init";
  designLoadUIState = "init";

  saving_potential = null;
  saving_potential_count = null;
  saving_potential_data = [];
  fetchedConsumptionTrendsData = null;
  dlcard_data = { ep: [], mz: [] };
  currentHour = null;
  lpMonth = null;
  lpYear = null;
  epMonth = null;
  yearlyRetTempSeries = {
    normal: [],
    measured: [],
    years: [],
  };

  fiveYearlyRetTempSeries = {
    normal: [],
    measured: [],
    years: [],
  };

  constructor(parent) {
    makeObservable(this, {
      fetchData: true,
      trendsConsumptionUIState: observable,
      resource_id: observable,
      resource_type: observable,
      retGraphs: observable,
      SavingPotUIState: observable,
      designLoadUIState: observable,
      saving_potential: observable,
      saving_potential_count: observable,
      saving_potential_data: observable,
      fetchedConsumptionTrendsData: observable,
      dlcard_data: observable,
      currentHour: observable,
      lpMonth: observable,
      lpYear: observable,
      epMonth: observable,
      yearlyRetTempSeries: observable,
      fiveYearlyRetTempSeries: observable,
      resetForNetwork: action.bound,
      changeCluster: action.bound,
    });

    this.parent = parent;
  }

  fetchData = flow(function* () {
    this.retGraphs = "fetch";
    this.SavingPotUIState = "fetch";
    this.designLoadUIState = "fetch";
    this.saving_potential = null;
    this.saving_potential_count = null;
    this.saving_potential_data = [];
    this.dlcard_data = { ep: [], mz: [] };
    this.yearlyRetTempSeries = {
      normal: [],
      measured: [],
      months: [],
    };
    this.fiveYearlyRetTempSeries = {
      normal: [],
      measured: [],
      years: [],
    };
    const blockNames = [];
    const calculations = [];
    const monthly_categories = [];
    for (const cdiff of range(0, 12)) {
      const k = this.lpMonth.plus({ months: -1 * cdiff });
      let blk;
      monthly_categories.unshift(Info.months("short")[k.month - 1]);
      blk = SBT.epcore_normalized_monthly.to_block_name({
        syear: this.lpYear.year,
        month: k.month,
        smonth: 12,
      });
      blockNames.push(blk);
      const c = { month: k.month, year: k.year, epblk: blk };
      blk = SBT.core.to_block_name({
        year: k.year,
        month: k.month,
      });
      blockNames.push(blk);
      c.cblk = blk;
      calculations.unshift(c);
    }

    const calculationFiveYear = [];
    for (const cdiff of range(0, 5)) {
      const k = this.lpYear.plus({ years: -1 * cdiff });
      let blk;
      blk = SBT.epcore_normalized.to_block_name({
        year: k.year,
        month: 12,
      });
      blockNames.push(blk);
      const c = { year: k.year, epblk: blk };
      blk = SBT.core.to_block_name({
        year: k.year,
      });
      blockNames.push(blk);
      c.cblk = blk;
      calculationFiveYear.unshift(c);
    }

    const dlcardBlocks = {};
    for (const cdiff of range(0, 5)) {
      const k = this.lpYear.plus({ years: -1 * cdiff });
      const ep = SBT.ep_design_1h.to_block_name({ year: k.year, month: 12 });
      const mz = SBT.measured_peak_power.to_block_name({
        year: k.year,
        month: 12,
        hist: "12month",
        interval: "1",
        res: "hour",
      });
      dlcardBlocks[k.year] = { ep, mz };
      blockNames.push(ep);
      blockNames.push(mz);
    }

    const dfs = yield this.parent.newapi.getInfoBlocksV4({
      resource_type: this.resource_type,
      network_id: this.parent.networks.current_network.uid,
      resource_id: this.resource_id,
      block_names: blockNames,
    });

    const activeYearCoreBlockName = SBT.core.to_block_name({ year: this.lpYear.year });
    const subblockNames = [];

    subblockNames.push(activeYearCoreBlockName);
    subblockNames.push(SBT.install_address.to_block_name());
    const subdfs = yield this.parent.newapi.getInfoBlocksV4({
      resource_type: "cluster_substations",
      resource_id: this.resource_id,
      block_names: subblockNames,
    });
    let have_data = false;
    if (have_blocks(subdfs, [activeYearCoreBlockName, BLOCK.install_address.name])) {
      have_data = true;
      const spReader = blkReader(subdfs, [
        ["derive", (row, index) => index, "id"],
        ["name", [activeYearCoreBlockName, SBT.core.col.heat_energy_sum], "energy"],
        ["name", [activeYearCoreBlockName, "returntemp_flowweighted_avg"], "rt"],
        ["name", [SBT.install_address.to_block_name(), SBT.install_address.col.street], "street"],
        [
          "derive",
          function (row) {
            return costSavingPotential(row, "energy", "rt", 1.24, 35);
          },
          "saving_potential",
        ],
      ]);
      const data = Array(subdfs[activeYearCoreBlockName].idxMap.size);
      let i = 0;
      for (const e of subdfs[activeYearCoreBlockName].idxMap.keys()) {
        data[i] = spReader(e);
        i = i + 1;
      }
      const sortedData = sortRows(data, "saving_potential", "desc");
      this.saving_potential_data = sortedData;
      const countSP = Math.min(sortedData.length, 30);
      const valueSP = sortedData
        .slice(0, countSP)
        .reduce((p, c) => ({ saving_potential: p.saving_potential + c.saving_potential }), []);
      this.saving_potential_count = countSP;
      this.saving_potential = valueSP.saving_potential;
    }
    if (!have_data) {
      this.SavingPotUIState = "no_data";
    } else {
      this.SavingPotUIState = "done";
    }

    let normal_series = [];
    let measured_series = [];
    for (const s of calculations) {
      if (have_blocks(dfs, [s.epblk])) {
        const val = getValueFromBlock(
          dfs,
          s.epblk,
          this.resource_id,
          "returntemp_flowweighted_avg"
        );
        normal_series.push(val);
      } else {
        normal_series.push(null);
      }
      if (have_blocks(dfs, [s.cblk])) {
        const val = getValueFromBlock(dfs, s.cblk, this.resource_id, "returntemp_flowweighted_avg");
        measured_series.push(val);
      } else {
        measured_series.push(null);
      }
    }
    this.yearlyRetTempSeries.normal = normal_series;
    this.yearlyRetTempSeries.measured = measured_series;
    this.yearlyRetTempSeries.months = monthly_categories;

    normal_series = [];
    measured_series = [];

    for (const s of calculationFiveYear) {
      let val = getValueFromBlock(dfs, s.epblk, this.resource_id, "returntemp_flowweighted_avg");
      normal_series.push(val);
      val = getValueFromBlock(dfs, s.cblk, this.resource_id, "returntemp_flowweighted_avg");
      measured_series.push(val);
    }
    this.fiveYearlyRetTempSeries.measured = measured_series;
    this.fiveYearlyRetTempSeries.normal = normal_series;
    this.fiveYearlyRetTempSeries.years = calculationFiveYear.map((c) => c.year);
    this.yearlyRetTempSeries.monthly_categories = monthly_categories;
    this.retGraphs = "done";
  });

  resetForNetwork() {
    const defaultResourceId = this.parent.networks.allSubstationCluster;
    if (defaultResourceId !== this.resource_id) {
      this.resource_id = defaultResourceId;
    }
    this.currentHour = this.parent.networks.CurrentHour;
    this.lpMonth = this.parent.networks.LastProcessedMonth;
    this.lpYear = this.parent.networks.LastProcessedYear;
    this.epMonth = this.parent.networks.LastEPMonth;
    this.dlcard_data = { ep: [], mz: [] };
    this.yearlyRetTempSeries = {
      normal: [],
      measured: [],
      years: [],
    };
    this.fiveYearlyRetTempSeries = {
      normal: [],
      measured: [],
      years: [],
    };
    this.saving_potential = null;
    this.saving_potential_count = null;
    this.saving_potential_data = [];
  }

  changeCluster(cluster) {
    this.resource_id = cluster;
  }
}

export default Store;
