/* eslint-disable react/jsx-props-no-spreading */
import type { PropsWithChildren } from "react";
import { ControlPosition, Map, MapControl, type MapProps } from "@vis.gl/react-google-maps";

import { MapDrawingControls } from "./controls/MapDrawingControls";
import { MapLoader } from "./Loader";
import type { Overlay } from "./types";

/**
 * UtfMap Props
 */
export type UtfMapProps = {
  defaultCenter?: google.maps.LatLngLiteral;
  drawingTools?: boolean;
  onDrawingsChange?: (drawings: Overlay[] | undefined) => void;
} & MapProps;

export function UtfMap({
  children,
  defaultCenter,
  drawingTools,
  onDrawingsChange,
  id,
  ...props
}: PropsWithChildren<UtfMapProps>) {
  return (
    <div data-testid={`utf-${props.mapId ?? "map"}`} style={{ height: "100%", width: "100%" }}>
      <MapLoader>
        <Map
          id={id}
          defaultZoom={12}
          defaultCenter={
            defaultCenter ?? {
              // Utilifeed Office Coords
              lat: 57.70055963189751,
              lng: 11.949964604273221,
            }
          }
          // disable drag-n-dropable-yellow-fella icon (street wiew)
          streetViewControl
          // remove controls to change map display type
          mapTypeControl={false}
          // "greedy": All touch * gestures and scroll events pan or zoom the map.
          gestureHandling="greedy"
          styles={[
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#f5f5f5",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "landscape.man_made",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#eeeeee",
                },
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "landscape.man_made",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#757575",
                },
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#dadada",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "transit.line",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e5e5e5",
                },
              ],
            },
            {
              featureType: "transit.station",
              elementType: "geometry",
              stylers: [
                {
                  color: "#eeeeee",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#c9c9c9",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
          ]}
          {...props}
        >
          {children}
          {/* if drawingToolsOn */}
          <MapControl position={ControlPosition.TOP_CENTER}>
            <MapDrawingControls onDrawingsChange={onDrawingsChange} enabled={drawingTools} />
          </MapControl>
        </Map>
      </MapLoader>
    </div>
  );
}
