import type { FC } from "react";
import { lazy } from "react";
import { flow } from "mobx";
import type { RouterState, RouterStore, TransitionHook } from "mobx-state-router";
import { createRouterState } from "mobx-state-router";
import {
  faCodeSimple,
  faGaugeHigh,
  faHouse,
  faIndustryWindows,
  faMap,
  faSplit,
  faSuitcase,
  faTableList,
  faUser,
  faUserGear,
} from "@fortawesome/pro-light-svg-icons";
import { setTags as setSentryTags, setUser as setSentryUser } from "@sentry/react";
import { sha256 } from "crypto-hash";
import posthog from "posthog-js";

import { isFeatureFlagOn, POSTHOG_ENABLED, SENTRY_ENABLED } from "@conf/config";
import FEATURE from "@conf/feature_access";
import { wikiData } from "@conf/wiki_constants";
import { logger as baseLogger } from "@core/logger";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

import DashPage from "./pages/dash";
import FeatureAccessDeniedPage from "./pages/FeatureAccessDenied";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/logout/logout.page";
import NotFoundPage from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy.page";

const MeteringDashPage = lazy(() => import("./pages/MeteringDash/Dashboard"));
const ProductionPage = lazy(() => import("./pages/Production"));
const ReturnTemperatureAnalysisPage = lazy(() => import("./pages/RTAnalysis"));
const ForeCastPage = lazy(() => import("./pages/ForeCast"));
const DesignLoadPage = lazy(() => import("./pages/DesignLoad"));
const Optimization = lazy(() => import("./pages/Optimization"));
const SubstationPage = lazy(() => import("./pages/DataLibrary"));
const DistributionDash = lazy(() => import("./pages/Distribution"));
const Mapview = lazy(() => import("./pages/MapView"));
const SalesDash = lazy(() => import("./pages/SalesDash"));
const PricingCombo = lazy(() => import("./pages/PricingCombo"));
const FaultDetection = lazy(() => import("./pages/MeteringDash/FaultDetection"));
const ScenarioAnalysis = lazy(() => import("./pages/ScenarioAnalysis"));
const ProfilePage = lazy(() => import("./pages/Profile"));
const SettingsPage = lazy(() => import("./pages/Profile/Settings"));

const BlockAdmin = lazy(() => import("./pages/BlockAdmin"));
const TestAdmin = lazy(() => import("./pages/TestAdmin"));
const OptionalTest = lazy(() => import("./pages/OptionalTest"));
const AboutPage = lazy(() => import("./pages/Profile/About"));

const logger = baseLogger.getSubLogger({ name: "router" });

const routeEntryHandler = (routeOpts: routeOptions): TransitionHook =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  flow(function* routeEntryHandlerFn(fromState, toState, routerStore) {
    const { access, requireAuth } = routeOpts;
    const accessRequired = access || [];
    // Default to true if not defined
    const requireAuthFlag = requireAuth === undefined ? true : requireAuth;

    logger.debug("beforeEnter", JSON.stringify(toState));
    const { session, networks, initialized: appInitialized } = routerStore.options.parent;

    // First just scroll to the top of the page on navigation.
    window.scrollTo(0, 0);

    // User tagging / identification
    if (SENTRY_ENABLED || POSTHOG_ENABLED) {
      logger.debug("Tagging user data for Sentry and Posthog...");
      const profile = yield session.getUserProfile();
      // We use the sub field as the userId, as it is unique.
      const userId = profile?.sub || "unknown";
      const userEmail = profile?.email || "";

      if (POSTHOG_ENABLED) {
        const postHogUserId = posthog.get_distinct_id();
        if (!postHogUserId || postHogUserId !== userId) {
          const allowedAnalytics = yield userAnalyticsEnabled(userEmail);
          if (allowedAnalytics) {
            posthog.identify(userId, {
              email: userEmail,
              name: profile?.nickname || profile?.name,
            });
          }
        }
      }

      if (SENTRY_ENABLED) {
        try {
          let groupTag = "";
          const [, profileEmailDomain] = userEmail.split("@");
          if (profileEmailDomain) groupTag = profileEmailDomain;
          let networkName = "";
          if (networks.current_network) networkName = networks.current_network.name;

          setSentryUser({
            id: userId,
            segment: groupTag,
            network: networkName,
          });
          setSentryTags({
            segment: groupTag,
            network: networkName,
            route: toState.routeName,
            pathname: window.location.pathname,
          });
        } catch (error) {
          logger.error("Sentry tagging failed!", error);
        }
      }
    }

    if (!requireAuthFlag) {
      return toState;
    }

    // Then we just check if the user is logged in.
    let isLoggedIn = yield session.checkSession();
    if (requireAuthFlag && isLoggedIn) {
      logger.debug("User authenticated...");

      // The "startup" will fetch networks and features
      // so "networks.haveAccess" can read enabled features
      if (fromState && fromState.routeName === "__initial__" && !appInitialized) {
        logger.debug("Running `rootStore.startup`...");
        yield routerStore.options.parent.startup();
      }

      // If the user is logged in, we check if they have access to the page.
      logger.debug("Checking network access for", accessRequired.toString());
      if (networks.haveAccess(accessRequired)) {
        logger.debug("Access Granted! Letting the user in...", toState.routeName);
        // If they have access, we inject the custom options for the route
        // This is done to have access to things like the wiki url in the routeState
        const toRoute = routerStore.routes.find((route) => route.name === toState.routeName);
        const modifiedToState = { ...toState };
        // @ts-expect-error - we mutation original router options. needs refactoring
        if (toRoute?.options) {
          // TODO: consider replacing this with createRouterState.
          // Expand options with toRoute.options.
          // @ts-expect-error - as same as above
          modifiedToState.options = { ...toRoute.options, ...toState.options };
        }
        return modifiedToState;
      }

      logger.info("Access Denied! Redirecting to the error page...", { accessRequired });
      // If they don't have access, we redirect them to the feature access denied page.
      return createRouterState("feature_access_denied");
    }

    // If the user is not logged in, and there is some state query, they might be busy with a login.
    if (window.location.search.includes("state=")) {
      yield session.handleRedirectCallback();
      // So we check if they are logged in now.
      isLoggedIn = yield session.checkSession();
      if (isLoggedIn) {
        // If they are logged in, we drop them on the dashboard
        logger.debug("Logged in. Redirecting to the Dashboard page...");
        return createRouterState("dash");
      }
    }

    // If the user is not logged in, then take them to the login page.
    logger.debug("Not authenticated! Redirecting to the Login page...");
    return createRouterState("login");
  });

export type UTFRoute = {
  component: FC;
  pattern: string;
  title?: string;
  pageIcon?: FC;
  options?: routeOptions;
};

type routeOptions = {
  access?: string[];
  wikiUrl?: { base: string; tabs?: Record<string, string> };
  filterAffects?: boolean;
  condition?: boolean;
  bare?: boolean;
  requireAuth?: boolean;
  onEnter?: (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => void;
};

type UTFRoutes = {
  [route: string]: UTFRoute;
};

export const routes: UTFRoutes = {
  dash: {
    pattern: "/",
    component: DashPage,
    title: "nav_home",
    pageIcon: () => <FontAwesomeSvgIcon icon={faHouse} />,
    options: {
      access: [FEATURE.home],
      wikiUrl: wikiData.dash,
    },
  },
  login: {
    pattern: "/login",
    component: LoginPage,
    options: {
      bare: true,
    },
  },
  notFound: {
    pattern: "/not-found",
    component: NotFoundPage,
    options: {
      bare: true,
    },
  },
  feature_access_denied: {
    pattern: "/feature-access-denied",
    component: FeatureAccessDeniedPage,
    title: "FeatureAccessDenied",
  },
  metering: {
    pattern: "/metering",
    component: MeteringDashPage,
    title: "nav_metering.name",
    pageIcon: () => <FontAwesomeSvgIcon icon={faGaugeHigh} />,
    options: {
      access: [FEATURE.metering],
      wikiUrl: wikiData.metering,
    },
  },
  sales: {
    pattern: "/sales",
    component: SalesDash,
    title: "nav_sales.name",
    pageIcon: () => <FontAwesomeSvgIcon icon={faSuitcase} />,
    options: {
      access: [FEATURE.sales],
      wikiUrl: wikiData.sales,
    },
  },
  distribution: {
    pattern: "/distribution",
    component: DistributionDash,
    title: "nav_distribution.sub.sub_nav_distribution_dashboard",
    pageIcon: () => <FontAwesomeSvgIcon icon={faSplit} />,
    options: {
      access: [FEATURE.dist],
      wikiUrl: wikiData.distribution,
    },
  },
  rt_analysis: {
    pattern: "/distribution/return_temperature_analysis",
    component: ReturnTemperatureAnalysisPage,
    title: "nav_distribution.sub.sub_nav_return_temperature_analysis",
    pageIcon: () => <FontAwesomeSvgIcon icon={faSplit} />,
    options: {
      access: [FEATURE.dist_rta, FEATURE.dist_rtsp],
      filterAffects: true,
      wikiUrl: wikiData.rt_analysis,
    },
  },
  design_load: {
    pattern: "/distribution/design_load",
    component: DesignLoadPage,
    title: "nav_distribution.sub.sub_nav_design_load",
    pageIcon: () => <FontAwesomeSvgIcon icon={faSplit} />,
    options: {
      access: [FEATURE.dist_dload],
      filterAffects: true,
      wikiUrl: wikiData.design_load,
    },
  },
  production: {
    pattern: "/production",
    component: ProductionPage,
    title: "nav_production.name",
    pageIcon: () => <FontAwesomeSvgIcon icon={faIndustryWindows} />,
    options: {
      access: [FEATURE.production],
      wikiUrl: wikiData.production,
    },
  },
  forecast: {
    pattern: "/forecast",
    component: ForeCastPage,
    title: "nav_production.sub.sub_nav_forecast",
    pageIcon: () => <FontAwesomeSvgIcon icon={faIndustryWindows} />,
    options: {
      access: [FEATURE.forecast],
      wikiUrl: wikiData.forecast,
    },
  },
  settings: {
    pattern: "/settings",
    component: SettingsPage,
    title: "nav_profile_and_settings.sub.sub_nav_setting",
    pageIcon: () => <FontAwesomeSvgIcon icon={faUserGear} />,
    options: {
      access: [FEATURE.profile],
    },
  },
  substations: {
    pattern: "/substations",
    component: SubstationPage,
    title: "nav_data_library",
    pageIcon: () => <FontAwesomeSvgIcon icon={faTableList} />,
    options: {
      access: [FEATURE.datalibrary],
      filterAffects: true,
      wikiUrl: wikiData.data_library,
    },
  },
  mapview: {
    pattern: "/mapview",
    component: Mapview,
    title: "nav_map_view",
    pageIcon: () => <FontAwesomeSvgIcon icon={faMap} />,
    options: {
      access: [FEATURE.mapview],
      filterAffects: true,
      wikiUrl: wikiData.mapview,
    },
  },
  pricing: {
    pattern: "/pricing",
    component: PricingCombo,
    title: "nav_sales.sub.sub_nav_pricing",
    pageIcon: () => <FontAwesomeSvgIcon icon={faSuitcase} />,
    options: {
      access: [FEATURE.pricing],
      filterAffects: true,
      wikiUrl: wikiData.pricing,
    },
  },
  profile: {
    pattern: "/profile",
    component: ProfilePage,
    title: "nav_profile_and_settings.sub.sub_nav_profile",
    pageIcon: () => <FontAwesomeSvgIcon icon={faUser} />,
    options: {
      access: [FEATURE.profile],
      wikiUrl: wikiData.rt_analysis,
    },
  },
  auth_management: {
    pattern: "/auth_management",
    component: ProfilePage,
    title: "nav_profile_and_settings.sub.sub_nav_profile",
    pageIcon: () => <FontAwesomeSvgIcon icon={faUser} />,
  },
  optimization: {
    pattern: "/optimization",
    component: Optimization,
    title: "nav_production.sub.sub_nav_optimization",
    pageIcon: () => <FontAwesomeSvgIcon icon={faIndustryWindows} />,
    options: {
      access: [FEATURE.optimization],
      wikiUrl: wikiData.optimization,
    },
  },
  fault_detection: {
    pattern: "/fault_detection",
    component: FaultDetection,
    title: "nav_metering.sub.sub_nav_fault_detection",
    pageIcon: () => <FontAwesomeSvgIcon icon={faGaugeHigh} />,
    options: {
      access: [FEATURE.fault_detection],
      filterAffects: true,
      wikiUrl: wikiData.fault_detection,
      onEnter(_: RouterState, __: RouterState, routerStore: RouterStore) {
        logger.debug("[onEnter] faultDetection");
        const {
          parent: { networks, faultDetection },
        } = routerStore.options;

        if (!networks.ready) return;
        faultDetection.init();
      },
    },
  },
  scenario_analysis: {
    pattern: "/scenario_analysis",
    // @ts-expect-error pages
    component: ScenarioAnalysis,
    title: "nav_production.sub.sub_nav_scenario_analysis",
    pageIcon: () => <FontAwesomeSvgIcon icon={faIndustryWindows} />,
    options: {
      access: [FEATURE.scenario_analysis],
      wikiUrl: wikiData.scenario_analysis,
    },
  },
  testing: {
    pattern: "/testing",
    component: OptionalTest,
    title: "Optional Test Pages",
    pageIcon: () => <FontAwesomeSvgIcon icon={faCodeSimple} />,
    options: {
      condition: isFeatureFlagOn("TEST_ADMIN") || isFeatureFlagOn("BLOCK_ADMIN"),
    },
  },
  block_admin: {
    pattern: "/testing/blockexplorer",
    component: BlockAdmin,
    title: "BlockAdmin",
    options: {
      condition: isFeatureFlagOn("BLOCK_ADMIN"),
    },
  },
  test_admin: {
    pattern: "/testing/general",
    component: TestAdmin,
    title: "TestAdmin",
    options: {
      condition: isFeatureFlagOn("TEST_ADMIN"),
    },
  },
  about: {
    pattern: "/about",
    component: AboutPage,
    title: "About",
  },
  privacy: {
    pattern: "/privacy_policy",
    component: PrivacyPolicy,
    title: "Privacy Policy",
    options: {
      requireAuth: false,
      bare: true,
    },
  },
  logout: {
    pattern: "/logout",
    component: LogoutPage,
    title: "Logout",
    options: {
      requireAuth: false,
      bare: true,
    },
  },
};

const routeOnEnterHandler = async (
  fromState: RouterState,
  toState: RouterState,
  routerStore: RouterStore
) => {
  logger.debug("onEnter %s", { fromState, toState });
  const { options } = toState;
  if (options?.onEnter) options.onEnter(fromState, toState, routerStore);
  return toState;
};

// These definitions used by RouterStore
export const storeRoutes = Object.entries(routes)
  .map(([routeName, route]) => {
    const { ...routeFiltererd } = route;

    // 1. beforeEnter
    if (route?.options?.condition === false) return false;
    const beforeEnter = routeEntryHandler(route?.options || {});
    // 2. onEnter
    const onEnter = routeOnEnterHandler;
    // 3. beforeExit
    // 4. onExit

    return { name: routeName, ...routeFiltererd, beforeEnter, onEnter };
  })
  .filter(Boolean);

const allowedEmailDomainHashes = [
  "fe44384b79c16beb608f3d2c6cd349b1c285c1f32c2177a5e8dffc712dff12d9",
  "090b235e9eb8f197f2dd927937222c570396d971222d9009a9189e2b6cc0a2c1",
  "26b6e80cd05f6d59029bfd82b25d32882021fb10702c296fb869740d9908b611",
  "68176a29a4b1c9fbcc295beeb2d0d3570859e723358614b1f8127f2c780eb279",
  "783e44aa956df56d637e2a7063c3815acc5460e01aba1e1c47a2fa7bc53c4feb",
  "3dfd3b33bf3af309c30298b6f11f883be9d355351b9592e7b0f113b3b717b14b",
  "db0f4cdf9be8dd207998e89893017ee196f6aac8d0e2efcb3968738fd2b13814",
  "299fab14e6c2ac1ccdb99e4f2f34a13ea5750a2764cd45563000f00057f8d83f",
  "9dce7ab55b4dc81d2fdea8523550f016ca6fd4fbcfe6bdd132e9111170246ac6",
];

async function userAnalyticsEnabled(userEmail: string) {
  if (userEmail && userEmail.length > 0) {
    const emailDomain = userEmail.split("@")[1];
    if (emailDomain) {
      // We encrypt the email domain to avoid storing PII in the FE. This is a one-way hash.
      // Info like email domain isnt really sensitive, but we hash it anyway to be responsible.
      const hash = await sha256(`@${emailDomain}`);
      if (allowedEmailDomainHashes.includes(hash)) {
        return true;
      }
    }
  }
  return false;
}
