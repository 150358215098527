import { createContext, useContext } from "react";
import { action, computed, flow, makeObservable, observable, runInAction } from "mobx";

import { logger } from "@core/logger";

import { JobStatus, ParameterType } from "../Optimization/Opt.const";

const log = logger.getSubLogger({ name: "scenario_analysis.store" });

const SA_CONFIG_TABS = {
  component: "components",
  settings: "settings",
};
export class ScenarioAnalysisStore {
  // Non observables
  // Simulation
  trainingStart;

  trainingEnd;

  simulationStart;

  simulationEnd;

  simulationResult;

  summary;

  // Configs and Components
  configurations;

  componentTypes;

  // Stores
  networkStore;

  notificationStore;

  // Observables
  selectedConfigIds = [];

  savedSimulations = [];

  fetching = false;

  fetchingComponents = false;

  loading = false;

  isSkeletonLoading = true;

  resultsReady = false;

  confDetailTab = "components";

  previousJobId = null;

  constructor(parent) {
    makeObservable(this, {
      deleteSavedSimulation: flow.bound,
      loadConfigurationComponents: flow.bound,
      deleteConfiguration: true,
      fetchResult: true,
      setSkeletonLoading: action.bound,
      isSkeletonLoading: observable,
      trainingStart: true,
      trainingEnd: true,
      simulationStart: true,
      simulationEnd: true,
      simulationResult: observable,
      summary: true,
      configurations: true,
      componentTypes: true,
      networkStore: true,
      notificationStore: true,
      fetchComponentTypes: flow.bound,
      formattedStateInput: true,
      hasEmptyStartState: true,
      selectedConfigIds: observable,
      fetching: observable,
      fetchingComponents: observable,
      loading: observable,
      resultsReady: observable,
      confDetailTab: observable,
      previousJobId: observable,
      savedSimulations: observable,
      // Functions
      reset: flow.bound,
      setSelectedConfigIds: action,
      updateComponentsSortOrder: flow.bound,
      updateSavedSimulation: flow.bound,
      fetchConfigComponents: flow,
      createComponent: flow,
      updateComponent: flow,
      deleteComponent: flow,
      createConfiguration: flow.bound,
      saveConfigurationSettings: flow.bound,
      loadConfigurations: flow.bound,
      updateInputState: action.bound,
      changeConfDetailTab: action.bound,
      runSimulation: flow.bound,
      updatePeriods: action.bound,
      saveResults: flow.bound,
      loadSavedSimulations: flow.bound,
      fetchSavedSimulationResults: flow.bound,
      copyConfiguration: flow.bound,
      // Getters
      selectedConfigurations: computed,
      hasPreviousJob: computed,
    });
    this.parent = parent;
    this.networkStore = parent.networks;
    this.notificationStore = parent.notifications;
    this.selectedConfigIds = [];
    this.configurations = [];

    // Check if a job id is stored in the local storage
    const jobId = localStorage.getItem("scenarioJobId");
    this.previousJobId = jobId;
  }

  //* /////////////////////////////
  //* Store State
  //* /////////////////////////////

  reset() {
    if (Array.isArray(this.networkStore.networkActiveMonth)) {
      this.configurations = [];
      this.selectedConfigIds = [];

      // Update observables
      runInAction(() => {
        this.loading = false;
        this.fetching = false;
        this.fetchingComponents = false;
        this.resultsReady = false;
      });
    }
  }

  get hasPreviousJob() {
    return !!this.previousJobId && this.previousJobId !== this.simulationResult?.meta_data?.task_id;
  }

  setSkeletonLoading(isLoading) {
    this.isSkeletonLoading = isLoading;
  }

  get selectedConfigurations() {
    if (this.selectedConfigIds.length > 0) {
      return this.configurations.filter((config) => this.selectedConfigIds.includes(config.id));
    }
    return [];
  }

  //* /////////////////////////////
  //* Simulation Functions
  //* /////////////////////////////

  /**
   * This function is used to update the input state of a specific parameter on a component
   * and then update the configuration array with the new config.
   * @param {number} configId - The id of the configuration
   * @param {object} state - The state object that contains the following properties:
   * @param {string} state.compId - The id of the component
   * @param {string} state.compName - The name of the component
   * @param {string} state.propId - The id of the parameter
   * @param {string} state.propName - The name of the parameter
   * @param {string} state.startState - The start state of the parameter
   * @param {string} state.endState - The end state of the parameter
   */
  updateInputState(configId, state) {
    this.loading = true;
    const config = this.configurations.find((c) => c.id === configId);
    const comp = config.components.find((c) => c.id === state.compId);
    const prop = comp.properties.find((p) => p.id === state.propId);
    prop.start_state = state.startState;
    prop.end_state = state.endState;
    const configIndex = this.configurations.findIndex((c) => c.id === configId);
    this.configurations[configIndex] = config;
    this.loading = false;
  }

  changeConfDetailTab(tab) {
    if (this.confDetailTab === tab) return;
    const configViews = Object.values(SA_CONFIG_TABS);
    if (!configViews.includes(tab)) return;

    this.confDetailTab = tab;
  }

  runSimulation = flow(function* runSimulation({
    simulationStartDate,
    simulationEndDate,
    trainingStartDate,
    trainingEndDate,
  }) {
    this.loading = true;
    this.fetching = true;
    this.resultsReady = false;

    if (this.hasEmptyStartState) {
      this.notificationStore.warning("All state input rows need a start state");
      this.fetching = false;
      this.loading = false;
      return;
    }
    // Set store dates
    this.simulationStart = simulationStartDate;
    this.simulationEnd = simulationEndDate;
    this.trainingStart = trainingStartDate;
    this.trainingEnd = trainingEndDate;

    try {
      const jobId = yield this.parent.opt_api.startSimulation({
        network_name: this.networkStore.current_network.name,
        network_id: this.networkStore.current_network.uid,
        start_time: this.simulationStart,
        stop_time: this.simulationEnd,
        training_start: this.trainingStart,
        training_stop: this.trainingEnd,
        config_id: this.selectedConfigIds[0],
        state_input: this.formattedStateInput,
        fetch_flex: false,
        send_flex: false,
        make_official: false,
        is_scenario: true,
      });

      // Store the job id in local storage
      localStorage.setItem("scenarioJobId", jobId);
      // Set job status to running
      this.jobStatus = JobStatus.RUNNING;
      this.simulationResult = null;
      // Fetch the result
      yield this.fetchResult(jobId);
      this.fetching = false;
      this.loading = false;
      this.resultsReady = true;
    } catch (err) {
      localStorage.removeItem("scenarioJobId");
      this.notificationStore.error(`Error during simulation: ${err}`);
      this.fetching = false;
      this.loading = false;
      this.resultsReady = false;
      throw err;
    }
  });

  fetchResult = flow(function* (jobId) {
    if (jobId) {
      try {
        // Poll the job status
        let error = null;
        while (this.jobStatus !== JobStatus.done && this.jobStatus !== JobStatus.error) {
          this.fetching = true;
          const jobStatus = yield this.parent.opt_api.getJobStatus(jobId);
          log.debug(`Job status: ${jobStatus.status}`);
          this.jobStatus = JobStatus[jobStatus.status];
          error = jobStatus.error;

          // if error, break
          if (error) {
            break;
          }
          // Wait 2 seconds before polling again
          yield new Promise((resolve) => {
            setTimeout(resolve, 2000);
          });
        }
        // If the job failed, throw an error
        if (this.jobStatus === JobStatus.error || error) {
          this.notificationStore.error(error || "Job failed");
          this.fetching = false;
          log.error(error || "Job failed");
          return;
        }
        // Fetch the result
        this.simulationResult = yield this.parent.opt_api.getSimulationResult(jobId);
        this.notificationStore.success("Simulation result fetched");
        this.fetching = false;
      } catch (err) {
        localStorage.removeItem("scenarioJobId");
        this.notificationStore.error(`Error fetching simulation result: ${err}`);
        this.fetching = false;
        log.error(err);
      }
    }
  });

  setSelectedConfigIds(ids) {
    this.selectedConfigIds = ids;
  }

  saveResults = flow(function* (simulation_name) {
    try {
      log.debug("Saving results...", this.simulationResult);
      yield this.parent.opt_api.saveSimulationResult(
        this.networkStore.current_network.uid,
        this.simulationResult.meta_data.task_id,
        simulation_name
      );
      this.notificationStore.success("Results saved");
      // Refetch minimal saved simulations
      yield this.loadSavedSimulations();
    } catch (err) {
      this.notificationStore.error(`Error saving results: ${err.toString()}`);
      log.error(err);
    }
  });

  loadSavedSimulations = flow(function* loadSavedSimulations() {
    log.debug("Fetching saved simulations...");
    this.loading = true;
    try {
      // We initially fetch the minimal data for the saved sims, since they can be quite big
      const fetchedSimulations = yield this.parent.opt_api.getSavedSimulationsMinimal(
        this.networkStore.current_network.uid,
        true
      );
      // Sort saved sims by id
      this.savedSimulations = fetchedSimulations.sort((a, b) => a.id - b.id);
      log.debug(`${this.savedSimulations.length} Saved simulations fetched`);
    } catch (err) {
      this.notificationStore.error(`Error during loading saved simulations: ${err.toString()}`);
      log.error(err);
    }
    this.loading = false;
  });

  fetchSavedSimulationResults = flow(function* fetchSavedSimulationResults(simulationId) {
    log.debug("Fetching saved simulation results...");
    this.loading = true;
    try {
      const fetchedSimulation = yield this.parent.opt_api.getSavedSimulationResults(
        this.networkStore.current_network.uid,
        simulationId
      );
      this.savedSimulations = this.savedSimulations.map((sim) => {
        if (sim.id === simulationId) {
          return fetchedSimulation;
        }
        return sim;
      });
      log.debug("Saved simulation results fetched");
    } catch (err) {
      this.notificationStore.error(
        `Error during fetching saved simulation results: ${err.toString()}`
      );
      log.error(err);
    }
    this.loading = false;
  });

  /**
   * This function is used to delete a saved simulation
   * @param {number} simulationId - The id of the simulation to delete
   */
  deleteSavedSimulation = flow(function* deleteSavedSimulation(simulationId) {
    this.loading = true;
    try {
      yield this.parent.opt_api.deleteSavedSimulation(
        this.networkStore.current_network.uid,
        simulationId
      );
      // Refetch minimal saved simulations
      yield this.loadSavedSimulations();
      this.notificationStore.success("Saved simulation deleted");
    } catch (err) {
      this.notificationStore.error(`Error deleting saved simulation: ${err.toString()}`);
      log.error(err);
    }
  });

  updateSavedSimulation = flow(function* (simulationId, simulationName) {
    try {
      yield this.parent.opt_api.updateSavedSimulation(
        this.networkStore.current_network.uid,
        simulationId,
        simulationName
      );

      // Refetch saved simulations minimal
      yield this.loadSavedSimulations();
      this.notificationStore.success("Saved simulation updated");
    } catch (err) {
      this.notificationStore.error(err.toString());
      log.error(err);
    }
  });

  //* ///////////////////////////
  //* Config methods
  //* ///////////////////////////

  createConfiguration = flow(function* createConfiguration() {
    yield this.parent.opt_api
      .createNewScenarioAnalysisConfig(this.networkStore.current_network.uid)
      .then(() => {
        this.notificationStore.success("Configuration created");
        this.loadConfigurations();
        return true;
      })
      .catch((err) => this.notificationStore.error(`Error creating configuration: ${err.message}`));
    yield this.loadConfigurations();
  });

  saveConfigurationSettings = flow(function* saveConfigurationSettings(config) {
    yield this.parent.opt_api
      .saveScenarioAnalysisConfig(this.networkStore.current_network.uid, config)
      .then(() => {
        this.notificationStore.success("Configuration saved");
        return true;
      })
      .catch((err) => this.notificationStore.error(`Error saving configuration: ${err.message}`));
  });

  copyConfiguration = flow(function* copyConfiguration(configId) {
    yield this.parent.opt_api
      .copyScenarioAnalysisConfig(this.networkStore.current_network.uid, configId)
      .then(() => {
        this.notificationStore.success("Configuration copied");
        return this.loadConfigurations();
      })
      .catch((err) => this.notificationStore.error(`Error copying configuration: ${err.message}`));
  });

  loadConfigurations = flow(function* loadConfigurations() {
    this.fetching = true;
    this.isSkeletonLoading = true;
    try {
      this.configurations = yield this.parent.opt_api.getScenarioAnalysisConfigurations(
        this.networkStore.current_network.uid
      );
    } catch (err) {
      this.parent.app.showMsg("error", `Unable to get configurations: ${err.error}`);
    }
    this.fetching = false;
    this.isSkeletonLoading = false;
  });

  //* ///////////////////////////
  //* Component methods
  //* ///////////////////////////

  fetchComponentTypes = flow(function* () {
    try {
      this.componentTypes = yield this.parent.opt_api.getScenarioAnalysisComponentTypes();
    } catch (err) {
      this.notificationStore.error(`Error loading component types: ${err.error}`);
    }
  });

  fetchConfigComponents = flow(function* fetchConfigComponents(configId) {
    this.fetchingComponents = true;
    let components = null;
    try {
      components = yield this.parent.opt_api.getScenarioConfigComponents(
        this.networkStore.current_network.uid,
        configId
      );
    } catch (err) {
      this.notificationStore.error(`Error fetching config components: ${err.error}`);
    }
    this.fetchingComponents = false;
    return components;
  });

  loadConfigurationComponents = flow(function* loadConfigurationComponents(configId) {
    this.loading = true;
    try {
      const components = yield this.fetchConfigComponents(configId);
      // Update the config components
      const configIndex = this.configurations.findIndex((c) => c.id === configId);
      this.configurations[configIndex].components = components;
    } catch (err) {
      this.notificationStore.error(`Error loading config components: ${err.error}`);
    }
    this.loading = false;
  });

  createComponent = flow(function* createComponent(configId, component) {
    this.loading = true;
    try {
      yield this.parent.opt_api.createScenarioAnalysisComponent(
        this.networkStore.current_network.uid,
        configId,
        component
      );

      // Fetch the config components and update the config components
      const configIndex = this.configurations.findIndex((c) => c.id === configId);
      this.configurations[configIndex].components = yield this.fetchConfigComponents(configId);
      this.notificationStore.success("Component created");
    } catch (err) {
      this.notificationStore.error(err.error || err);
    }
    this.loading = false;
  });

  deleteComponent = flow(function* deleteComponent(configId, componentId) {
    this.loading = true;
    try {
      yield this.parent.opt_api.deleteScenarioAnalysisComponent(
        this.networkStore.current_network.uid,
        configId,
        componentId
      );

      // Update the config components
      const configIndex = this.configurations.findIndex((c) => c.id === configId);
      this.configurations[configIndex].components = yield this.fetchConfigComponents(configId);
      this.notificationStore.success("Component deleted");
    } catch (err) {
      this.notificationStore.error(`Error deleting component: ${err.error}`);
      throw err;
    }
    this.loading = false;
  });

  updateComponentsSortOrder = flow(function* updateComponentSortOrder(configId, components) {
    this.loading = true;
    let updatedComponents = null;
    try {
      yield this.parent.opt_api
        .updateComponentsSortOrder(this.networkStore.current_network.uid, configId, components)
        .then(() => {
          updatedComponents = components;
          return components;
        });

      this.notificationStore.success("Sort order updated");
    } catch (err) {
      this.notificationStore.error(`Error updating component sort order: ${err.error}`);
      throw err;
    } finally {
      // Update the config components
      const configIndex = this.configurations.findIndex((c) => c.id === configId);
      this.configurations[configIndex].components = updatedComponents;
      this.loading = false;
    }
  });

  deleteConfiguration = flow(function* deleteConfiguration(configId) {
    this.loading = true;
    try {
      yield this.parent.opt_api.deleteScenarioAnalysisConfiguration(
        this.networkStore.current_network.uid,
        configId
      );

      // load the config after update
      this.loadConfigurations();
    } catch (err) {
      this.notificationStore.error(`Error deleting component: ${err.error}`);
      throw err;
    }
    this.loading = false;
  });

  updateComponent = flow(function* updateComponent(configId, component) {
    this.loading = true;
    try {
      yield this.parent.opt_api.updateScenarioAnalysisComponent(
        this.networkStore.current_network.uid,
        configId,
        component
      );

      // Update the config components
      const configIndex = this.configurations.findIndex((c) => c.id === configId);
      this.configurations[configIndex].components = yield this.fetchConfigComponents(configId);
      this.notificationStore.success("Component updated");
    } catch (err) {
      this.notificationStore.error(`Error updating component: ${err.error}`);
      throw err;
    }
    this.loading = false;
  });

  updatePeriods(periods) {
    this.trainingStart = periods.trainingStart;
    this.trainingEnd = periods.trainingEnd;
    this.simulationStart = periods.simulationStart;
    this.simulationEnd = periods.simulationEnd;
  }

  //* ///////////////////////////
  //* Helper methods
  //* ///////////////////////////

  /**
   * This function rebuilds the state input object for the selected configuration
   * @returns {object}
   */
  get formattedStateInput() {
    return this.selectedConfigurations.reduce((acc, config) => {
      config.components.forEach((comp) => {
        comp.properties
          .filter((prop) => prop.type === ParameterType.STATE_INPUT)
          .forEach((prop) => {
            acc.push({
              comp_id: comp.id,
              prop_id: prop.id,
              start_state: prop.start_state,
              end_state: prop.end_state,
            });
          });
      });
      return acc;
    }, []);
  }

  get hasEmptyStartState() {
    if (this.selectedConfigIds.length) {
      return this.selectedConfigurations.some((config) =>
        config.components.some((component) =>
          component.properties.some((prop) => {
            if (prop.type === ParameterType.STATE_INPUT) {
              // If the start state is empty, or doesn't exist, return true
              return !prop.start_state || prop.start_state === "";
            }
            return false;
          })
        )
      );
    }
    return true;
  }
}

export const ScenarioAnalysisContext = createContext();

export default function useScenarioAnalysis() {
  return useContext(ScenarioAnalysisContext);
}
