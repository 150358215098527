import { action, autorun, computed, flow, makeObservable, observable } from "mobx";

import { logger as baseLogger } from "@core/logger";
import { sleep } from "@core/utils";

const logger = baseLogger.getSubLogger({ name: "filterStore" });

function getFilterStorageInLocal() {
  const filterStorage = localStorage.getItem("filters");
  return JSON.parse(filterStorage);
}

class UserPreferences {
  fav_subs = new Map();

  currentUserID = null;
  profile = null;
  networkId = null;

  constructor(parent) {
    makeObservable(this, {
      currentUserID: true,
      getFilterStorage: true,
      clearFilterStorage: true,
      upsertFilterStorage: true,
      checkIfFilterInStorage: true,
      networkId: observable,
      profile: observable,
      currentUser: computed,
      upsertSimResultCollapsible: true,
      currentNetworkId: computed,
      disableSync: true,
      toggleFavSub: true,
      clearFavorites: true,
      toJson: true,
      fav_subs: observable,
      resetPref: action.bound,
      fromJson: action.bound,
      getSimulationResultInLocalStorage: action.bound,
      getSimResultCollapsibleTab: action.bound,
    });

    this.parent = parent;
    this._enabled = true;
    this._update_pending = false;

    autorun(async () => {
      try {
        this.profile = await this.parent.utfapi?.getUserProfile();
        this.networkId = this.parent.networks.current_network?.uid;
      } catch (error) {
        logger.error("Error initializing user_preferences store: ", error);
      }
    });

    this.disableSync = this.disableSync.bind(this);

    autorun(() => {
      this.profile = this.parent.session?.isLoggedIn ? this.parent.utfapi?.getUserProfile() : null;
    });
  }

  get currentUser() {
    return this.profile?.name;
  }

  disableSync() {
    this._enabled = false;
  }

  toggleFavSub = flow(function* (network_id, sub_id) {
    let cnet = null;
    if (this.fav_subs.has(network_id)) {
      cnet = this.fav_subs.get(network_id);
    } else {
      cnet = new Set();
      this.fav_subs.set(network_id, cnet);
    }
    if (cnet.has(sub_id)) {
      cnet.delete(sub_id);
    } else {
      cnet.add(sub_id);
    }

    if (!this._update_pending && this._enabled) {
      this._update_pending = true;
      yield sleep(10);
      yield this.parent.utfapi.setPreferences(this.toJson());
      this._update_pending = false;
    }
    yield this.parent.filters.updateFavouritesFilter();
  });

  resetPref() {
    /* reset preferences to initial */
    this.fav_subs = new Map();
  }

  clearFavorites = flow(function* (network_id) {
    this.fav_subs.set(network_id, new Set());
    if (!this._update_pending && this._enabled) {
      this._update_pending = true;
      yield sleep(10);
      yield this.parent.utfapi.setPreferences(this.toJson());
      this._update_pending = false;
    }
  });

  fromJson(jsn) {
    if (Object.prototype.hasOwnProperty.call(jsn, "fav_subs")) {
      this.fav_subs = new Map();
      for (const net of Object.keys(jsn.fav_subs)) {
        this.fav_subs.set(net, new Set(jsn.fav_subs[net]));
      }
    }
  }

  toJson() {
    const resjson = { fav_subs: {} };
    for (const netFavSub of this.fav_subs.entries()) {
      resjson.fav_subs[netFavSub[0]] = Array.from(netFavSub[1]);
    }
    return resjson;
  }

  upsertSimResultCollapsible(headingTitle) {
    logger.debug(`Upserting ${headingTitle} in simResultCollapsible in localStorage`);
    let simResultCollapsible = this.getSimulationResultInLocalStorage();
    let collapsibleByTitle = false;
    const userName = this.profile.name;
    const collapsibleByNetworkAndUserId =
      simResultCollapsible?.simResultCollapse?.[this.networkId]?.[userName];
    // update simulation collapse status if exist
    if (Object.prototype.hasOwnProperty.call(collapsibleByNetworkAndUserId, headingTitle)) {
      collapsibleByTitle = !collapsibleByNetworkAndUserId?.[headingTitle];
    }
    // update simulation collapse tab
    if (collapsibleByNetworkAndUserId) {
      simResultCollapsible.simResultCollapse[this.networkId][this.profile?.name][headingTitle] =
        collapsibleByTitle;
    }
    // add simulation collapse tab
    else {
      simResultCollapsible = {
        simResultCollapse: {
          [this.networkId]: {
            [userName]: {
              [headingTitle]: false,
            },
          },
        },
      };
    }
    localStorage.removeItem("optAndScenarioPreferences");
    localStorage.setItem("optAndScenarioPreferences", JSON.stringify(simResultCollapsible));
  }
  getSimulationResultInLocalStorage() {
    return JSON.parse(localStorage.getItem("optAndScenarioPreferences"));
  }
  // get remember simResultCollapse state by default
  getSimResultCollapsibleTab(headingTitle) {
    const simResultCollapsible = this.getSimulationResultInLocalStorage();
    const simResultCollapsibleTab =
      simResultCollapsible?.simResultCollapse?.[this.networkId]?.[this.profile.name]?.[
        headingTitle
      ];
    if (simResultCollapsibleTab === undefined || simResultCollapsibleTab === null) {
      return true;
    }
    return simResultCollapsibleTab;
  }

  get currentNetworkId() {
    return this.parent.networks.current_network?.uid || null;
  }

  /**
   * convert this.filters.data from array to Map
   * to avoid error TypeError filterData?.data.get is not a function
   * in active_substations networks.js
   */
  getFilterStorage() {
    const filterStorage = getFilterStorageInLocal();
    const filterStorageByNetwordAndUserID =
      filterStorage.filters[this.currentNetworkId][this.currentUserID];
    return filterStorageByNetwordAndUserID.map((filter) => ({
      ...filter,
      data: new Map(filter.data),
    }));
  }

  clearFilterStorage() {
    localStorage.removeItem("filters");
  }

  upsertFilterStorage(filters) {
    logger.debug("Upserting Filter in Local Storage ...");
    let filterStorage = getFilterStorageInLocal();

    if (!filterStorage) {
      filterStorage = {};
    }

    // Create an empty object for the network ID if it doesn't exist
    if (!filterStorage?.filters) {
      filterStorage.filters = {};
    }
    filterStorage.filters[this.currentNetworkId] =
      filterStorage.filters[this.currentNetworkId] || {};

    // Update filters for the current user under the network ID
    filterStorage.filters[this.currentNetworkId][this.currentUserID] = filters;

    // Persist the updated storage
    this.clearFilterStorage();
    localStorage.setItem("filters", JSON.stringify(filterStorage));
  }

  checkIfFilterInStorage() {
    const filterStorage = getFilterStorageInLocal();
    return !!filterStorage?.filters?.[this.currentNetworkId]?.[this.currentUserID];
  }
}

export default UserPreferences;
