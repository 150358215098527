import { defaultTheme } from "@conf/utilifeed.theme";
import {
  TOOLBOX_OPTION_AVERAGE,
  TOOLBOX_OPTION_MAX,
  TOOLBOX_OPTION_MIN,
} from "@config/chartToolbox";
import { logger as baseLogger } from "@core/logger";
import {
  formatNumberForLocale,
  formatNumberForUnitMainLabel,
  numbersOnly,
  SI_PREFIXES,
} from "@core/utils";

import FUnitValueHtml from "../FunitValueHTML";

const logger = baseLogger.getSubLogger({ name: "consumption-util" });

const getAverageValues = (values) => values.reduce((a, b) => a + b, 0) / values.length || 0;

const getToolValue = (chart, type, seriesIndex) => {
  const series = chart.series[seriesIndex];
  const yData = (series?.processedYData || []).filter(numbersOnly);

  switch (type) {
    case TOOLBOX_OPTION_AVERAGE:
      return getAverageValues(yData);

    case TOOLBOX_OPTION_MIN:
      return series?.dataMin;

    case TOOLBOX_OPTION_MAX:
      return series?.dataMax;

    default:
      return 0;
  }
};

const addShapeLine = ({ seriesIndex, color, yAxis, type }) => ({
  stroke: color,
  type: "path",
  dashStyle: "LongDash",
  points: [
    function annotationPointStart({ chart }) {
      return {
        x: chart.xAxis[0].min,
        y: getToolValue(chart, type, seriesIndex),
        xAxis: 0,
        yAxis,
      };
    },
    function annotationPointEnd({ chart }) {
      return {
        x: chart.xAxis[0].max,
        y: getToolValue(chart, type, seriesIndex),
        xAxis: 0,
        yAxis,
      };
    },
  ],
});

const addLabel = ({ seriesIndex, label, yPos, valueSuffix, value, yAxis, type, textColor }) => ({
  y: yPos,
  x: 30,
  padding: 4,
  shape: "rect",
  formatter() {
    const { chart } = this.series;
    const seriesId = chart.series[seriesIndex].userOptions.id;
    return `<span data-testid="${seriesId}-${type}">${label}: ${formatNumberForLocale(
      value || this.y
    )} ${valueSuffix}</span>`;
  },
  style: {
    color: textColor,
    fontSize: "11px",
    fontFamily: defaultTheme.typography.fontFamily,
  },
  point({ chart }) {
    return {
      x: chart.xAxis[0].min,
      y: getToolValue(chart, type, seriesIndex),
      xAxis: 0,
      yAxis,
    };
  },
});

function createToolboxAnnotation({
  seriesIndex,
  yAxis,
  value,
  valueSuffix,
  type,
  label,
  yPos,
  color,
  textColor,
  isRefSeries,
}) {
  return {
    draggable: "x",
    labelOptions: {
      allowOverlap: true,
      backgroundColor: color,
      opacity: 1,
      borderColor: "transparent",
    },
    shapes: [
      addShapeLine({
        seriesIndex,
        color,
        yAxis,
        type,
      }),
    ],
    labels: [
      addLabel({
        seriesIndex,
        type,
        label: isRefSeries ? `Ref ${label}` : label,
        yPos,
        valueSuffix,
        value,
        yAxis,
        textColor,
      }),
    ],
  };
}

function populateRowsFromSeries(series) {
  let seriesWithData = [];
  try {
    series.forEach((serie, seriesIndex) => {
      if (!serie.timeStamp) return;

      if (!seriesWithData[seriesIndex]) seriesWithData[seriesIndex] = [];

      if (!serie.visible) return;

      const isObject = serie.data[0] !== null && typeof serie.data[0] === "object";
      serie.timeStamp.forEach((x, xIndex) => {
        let y = serie.data[xIndex];
        if (isObject) {
          y = y?.y;
        }

        if (!numbersOnly(y)) return;

        seriesWithData[seriesIndex].push(y);
      });
    });
  } catch (e) {
    logger.error("Cannot generate timeseries data for calculations!");
    seriesWithData = [];
  }
  return seriesWithData;
}

const optionsToAnnotate = [TOOLBOX_OPTION_MIN, TOOLBOX_OPTION_MAX, TOOLBOX_OPTION_AVERAGE];
export function generateToolboxAnnotations({ series, options }) {
  const aciveOptionsToAnnotate = options.filter((option) => optionsToAnnotate.includes(option));
  if (!aciveOptionsToAnnotate.length) return [];

  const annotations = [];
  // Create "timestamp" to "value" array of series
  const rowsOfSeries = populateRowsFromSeries(series);

  rowsOfSeries.forEach((yAxisData, seriesIndex) => {
    const { tooltip, yAxis } = series[seriesIndex];
    const { valueSuffix } = tooltip;
    const labelColors = {
      color: series[seriesIndex].color,
      textColor: series[seriesIndex].textColor || "white",
    };
    const isRefSeries = series[seriesIndex]?.ref === true;

    if (yAxisData?.length <= 0) return;

    aciveOptionsToAnnotate.forEach((option) => {
      let value = 0;

      switch (option) {
        // MINIMUM LABEL & REFERENCE LINE
        case TOOLBOX_OPTION_MIN:
          value = Math.min.apply(null, yAxisData);
          annotations.push(
            createToolboxAnnotation({
              seriesIndex,
              yAxis,
              value,
              valueSuffix,
              type: TOOLBOX_OPTION_MIN,
              label: "Min",
              yPos: 20,
              isRefSeries,
              ...labelColors,
            })
          );
          break;

        // MAXIMUM LABEL & REFERENCE LINE
        case TOOLBOX_OPTION_MAX:
          value = Math.max.apply(null, yAxisData);
          annotations.push(
            createToolboxAnnotation({
              seriesIndex,
              yAxis,
              value,
              valueSuffix,
              type: TOOLBOX_OPTION_MAX,
              label: "Max",
              yPos: -1,
              isRefSeries,
              ...labelColors,
            })
          );
          break;

        // AVERAGE LABEL & REFERENCE LINE
        case TOOLBOX_OPTION_AVERAGE:
          value = getAverageValues(yAxisData);
          annotations.push(
            createToolboxAnnotation({
              seriesIndex,
              yAxis,
              value,
              valueSuffix,
              type: TOOLBOX_OPTION_AVERAGE,
              label: "Avg",
              yPos: 9,
              isRefSeries,
              ...labelColors,
            })
          );
          break;
        default:
          break;
      }
    });
  });

  return annotations;
}

export const INCLUDES_UNIT = ["kW", "SEK/kWh", "NOK/kWh", "kWh"];

/**
 * the tooltip number shown should correspond to a prefix 1000x lower than the title prefix.
 * E.g. if the title prefix is GWh, the hover prefix is MWh.
 *
 * The lowest allowed prefix is k (e.g. kW, kWh)
 * @param {number} value
 * @param {string} unit
 */
export const formatTooltipValue = (value, unit) => {
  if (INCLUDES_UNIT.includes(unit)) {
    const [, , mainPrefix, prefixUnit] = formatNumberForUnitMainLabel(value, unit);
    const prefixOrder = Object.keys(SI_PREFIXES);
    const prefixIndex = prefixOrder.indexOf(mainPrefix);
    const mainValue = value.toString().split(".")[0];
    let mainUnit = "";

    if (prefixIndex > 0) {
      mainUnit = prefixOrder[prefixIndex - 1];
    }

    // The lowest allowed prefix is k
    if (mainUnit === "") {
      mainUnit = "k";
    }
    return `${mainValue} ${mainUnit}${prefixUnit}`;
  }
  return FUnitValueHtml(value, unit);
};

export const formatYAxisLabel = (value, maxValue, yUnit, isLast) => {
  const yAxisUnitLabel = (displayUnit, lastTickValue) =>
    `<span><span style="font-size:13px; color:${defaultTheme.palette.secondary.main};font-family:${defaultTheme.typography.body2.fontFamily}">${displayUnit}</span><br /><span style="float: right;">${lastTickValue}</span></span>`;

  let scaleExponent = 0;
  let scaledPrefix = "";
  let scaledUnit = yUnit;
  let scaledValue = value;

  if (maxValue && INCLUDES_UNIT.includes(yUnit)) {
    const [, , prefix, unit, scaledExp] = formatNumberForUnitMainLabel(maxValue, yUnit);
    scaledUnit = unit;
    scaledPrefix = prefix;
    scaleExponent = scaledExp; // This is wrong, function should return scaling used
    scaledValue = value / 10 ** scaleExponent;
  }

  return isLast
    ? yAxisUnitLabel(`${scaledPrefix}${scaledUnit}`, scaledValue)
    : `<span>${scaledValue}</span>`;
};
