/*
we would want this to be reactive to events rather than autosync. hence the implementation is rather dumb.
*/
import { reaction } from 'mobx';

export function autoSave(store, save) {
    let firstRun = true;
    reaction(
        store.shouldStore,
        (data, reaction) => {
            if (!firstRun) {
                store.saveToCache(data, reaction);
            }
            firstRun = false;
        },
        { fireImmediately: true }
    )
}

class Cache {
    // localstorage implementation.

    constructor(parent) {
        this.parent = parent;
    }

    getItem(key, otherwise = null) {
        let res = localStorage.getItem(key)
        return res ? res : otherwise;
    }

    setItem(key, value) {
        localStorage.setItem(key, value)
    }

    removeItem(key) {
        localStorage.removeItem(key);
    }

}

export default Cache;